import React from 'react'

const PersonalBreakOrder = ({ order, mine }) => {
    return (
        <div className={`card card-team slim ${mine ? 'card-mine' : ''}`}>
            <div className="card-top">
                <div className="sort-num">#{order.id}</div>
                <div className="label" style={{ padding: '10px' }}>
                    <span>{order.username}</span>
                    <div className="break-name" style={{ textTransform: 'none' }}>
                        {order.break_name}
                        (QTY: {order.quantity})
                        - {order.status.split("_").join(' ')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalBreakOrder