import React from 'react'

const Header = () => {
    return (
        <div className="row justify-content-center mb-4">
            <div className="col-sm-12 col-md-8">
                <h1 className="page-header">Draft Preferences</h1>
                <p className="intro">
                    Set your team draft preferences by sport and year When each break
                    fills, all names are randomized 3 times using the built in Random.org API.
                    It will automatically draft your next best available team based on the random
                    draft pick you have been assigned!
                </p>
                <p className="intro">Get started by selecting a sport and year; then drag the team cards to your desired draft preferences order.</p>
            </div>
        </div>
    )
}

export default Header