import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

const SortableFaq = SortableElement(({ faq }) => {
    return (
        <div className="card mb-3" style={{ cursor: 'grab' }}>
            <div className="card-body">
                <span><strong>Q:&nbsp;</strong></span>
                <span dangerouslySetInnerHTML={{ __html: faq.question.body }}></span>
                <br />
                <span><strong>A:&nbsp;</strong></span>
                <span dangerouslySetInnerHTML={{ __html: faq.answer.body }}></span>
            </div>
        </div>
    )
})

export default SortableFaq