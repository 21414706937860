import React from 'react';
import axios from 'axios';
import Break from './Break';
import PersonalBreakHomeCard from '../home/PersonalBreakHomeCard';
import AuctionHomeCard from '../home/AuctionHomecard';

export default class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rewardPoints: 0
        };
    }

    componentDidMount() {
        if (this.props.userSignedIn) {
            axios.get(`/users/${this.props.user.id}/reward_points_balance.json`).then(response => {
                this.setState({ rewardPoints: response.data.balance });
            })
        }
    }

    breakCount = () => {
        return this.props.breaks.length + (this.props.personalBreaks ? 1 : 0);
    }

    render = () => {
        let index = 0;

        return (
            <div className={`page ${this.props.hideHeader ? 'no-pad' : 'stadium-bg'} ${this.breakCount() > 0 ? 'has-breaks' : ' '}`} id="">
                <div className="container-xl">
                    {
                        this.props.hideHeader ? null : (
                            <div className="row justify-content-center mb-4">
                                <div className="col-md-8">
                                    <h1 className="page-header">{this.props.header}</h1>
                                    {this.props.children}
                                </div>
                            </div>
                        )
                    }
                    <div className={`break-container break-container-${this.breakCount() > 4 ? 'many' : this.breakCount()}`}>
                        {
                            !this.props.breaks.length && this.props.showEmpty && this.props.loaded && (
                                <h1 style={{ width: '100%', textAlign: 'center' }}>All spots are sold out right now, check back soon.</h1>
                            )
                        }
                        {
                            this.props.breaks.map(bk => {
                                return (
                                    <Break
                                        key={bk.id}
                                        break={bk}
                                        token={this.props.token}
                                        userSignedIn={this.props.userSignedIn}
                                        user={this.props.user}
                                        index={++index}
                                        rewardPoints={this.state.rewardPoints}
                                        profileComplete={this.props.profileComplete}
                                        breakDetailsModalId={this.props.breakDetailsModalId}
                                        personal={this.props.personal} />
                                );
                            })
                        }
                        {
                            this.props.personalBreaks ? (
                                <PersonalBreakHomeCard index={++index} />
                            ) : null
                        }
                        {
                            this.props.auctions ? (
                                <AuctionHomeCard index={++index} />
                            ) : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}
