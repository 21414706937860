/**
 * Helper class for sport related stuff
 */
 export default class Sport {
  /**
   * Get the icon/image path for a sport
   *
   * @param {*} sport the team object from the API
   */
  static imagePath = (sportId) => {
    const BASEBALL = 1;
    const FOOTBALL = 2;
    const BASKETBALL = 3;

    if (sportId === BASEBALL) {
      return `/images/mlb-logo.png`;
    } else if (sportId === FOOTBALL) {
      return `/images/nfl-logo.png`;
    } else if (sportId === BASKETBALL) {
      return `/images/nba-logo.png`;
    }
  }
}