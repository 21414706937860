import React, { useEffect, useState } from 'react'

const AuctionBidHistory = (props) => {

	const [bids, setBids] = useState([]);

	useEffect(() => {
		setBids(props.bids)
	}, [props.auctionItem.id])

	const convertDate = (date) => {
		return new Date(date).toLocaleDateString();
	}
	
	const convertTime = (date) => {
		return new Date(date).toLocaleTimeString('en-US', {
			hour: "2-digit",
			minute: "2-digit"
		});
	}

	return (
		<tr className="auction-item-log">
			<td colSpan="5">
				<div className="auction-item-log-container">
					<h4>Bid History</h4>
					<table>
						<thead>
							<tr>
								<th>Bidder</th>
								<th>Bid Amount</th>
								<th>Bid Time</th>
							</tr>
						</thead>
						<tbody>
							{bids.map((bid, index) => {
								return (
									<tr key={index}>
										<td>{bid.username}</td>
										<td>${parseFloat(bid.current_bid).toFixed(2)}</td>
										<td>{convertDate(bid.created_at)} at {convertTime(bid.created_at)}</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</td>
		</tr>
	)
}

export default AuctionBidHistory;