import React from 'react';
import DateTimePicker from 'react-datetime-picker';

export default class Form extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            date_on_sale: this.props.break.on_sale_date || new Date()
        }
    }

    handleDateTimeUpdate = (value) => {
        this.setState({ on_sale_date: value });
    }

    render() {
        return (
            <div className="form-group">
                <label>On Sale Date</label>
                <DateTimePicker
                    onChange={this.handleDateTimeUpdate}
                    name="personal_break[on_sale_date]"
                    value={this.state.on_sale_date ? new Date(this.state.on_sale_date) : new Date()} />
            </div>
        )
    }
    
}

