import React from 'react'

const FormInputs = (props) => {
    return (
        <div>
            <div className="form-group">
                <label>Name</label>
                <input
                    required
                    name="draft_preference[name]"
                    type="text"
                    className="form-control"
                    defaultValue={props.draft_preference.name || ''} />
            </div>

            <div className="form-group">
                <label>Year</label>
                <input
                    disabled={props.draft_preference.multi_year}
                    required
                    name="draft_preference[year]"
                    type="text"
                    className="form-control"
                    defaultValue={props.draft_preference.year || ''} />
            </div>

            <div className="form-group">
                <label>Sport</label>
                <select
                    required
                    onChange={props.handleSportSelect}
                    name="draft_preference[sport_id]"
                    className="form-control"
                    value={props.selectedSportId}>

                    <option value=""></option>

                    {
                        props.sports.map((sport, key) => {
                            return (
                                <option value={sport.id} key={key}>{sport.name}</option>
                            )
                        })
                    }

                </select>
            </div>

            <div className="form-group">
                <label htmlFor="enabled-check">Enabled</label>
                <input
                    id='enabled-check'
                    name="draft_preference[enabled]"
                    type="checkbox"
                    className="form-control checkbox" 
                    defaultChecked={props.draft_preference.enabled}/>
            </div>

        </div>
    )
}

export default FormInputs
