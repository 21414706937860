import React from 'react'
import BreakPurchaseRow from './BreakPurchaseRow'

const BreakDetailsActionRow = ({ guest, activeUser, spotsRemaining, loading, bk, setLoading, token, rewardPoints, profileComplete }) => {
    /**
     * For guests, only show in the sign in link.
     */
    if (guest) {
        return (
            <div className='text-center'>
                <h5>
                    <a href="/users/sign_in">Sign In to Purchase</a>
                </h5>
            </div>
        )
    }

    else if (!profileComplete) {
        return (
            <div className='text-center'>
                <h5>
                    <a href="/users/profile">You Must Complete Your Profile Before Purchasing</a>
                </h5>
            </div>
        );
    }

    /**
     * If there is a user, make sure that they are an active user in the system.
     */
    else if (activeUser) {
        return (
            <BreakPurchaseRow
                spotsRemaining={spotsRemaining}
                loading={loading}
                break={bk}
                setLoading={setLoading}
                token={token}
                rewardPoints={rewardPoints} />
        )
    }

    /**
     * Inactive users see no action to take on the break.
     */
    else {
        return null
    }
}

export default BreakDetailsActionRow