import React from 'react'
import Marquee, { Motion } from 'react-marquee-slider'

const TextMarquee = props => {
    return (
            <Marquee>
                {
                    props.text.map((text, index) => (
                        <div key={index} className='marquee-message'>
                            {text}
                        </div>
                    ))
                }
            </Marquee>
        
    )
}

export default TextMarquee
