import React from 'react'
import axios from 'axios'
import ChatMessages from './ChatMessages'
import ChatInput from './ChatInput'
import Cable from '../../lib/Cable'

export default class Chat extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            messages: [],
            messagesRef: React.createRef()
        }
    }

    componentDidMount() {
        axios.get('/chat_messages.json').then(response => {
            this.setState({ messages: response.data });
            this.scrollMessages();
        })

        Cable.connect('ChatChannel', {
            received: data => {
                switch (data.type) {
                    case 'delete':
                        // remove message from state
                        this.setState({
                            messages: this.state.messages.filter(m => {
                                return m.message.id !== data.message.message.id
                            })
                        });
                        break;
                    default:
                        // new message
                        this.setState({ messages: this.state.messages.concat(data.message) });
                }
                this.scrollMessages();
            }
        })
    }

    scrollMessages = () => {
        this.state.messagesRef.current.scrollTo(0, this.state.messagesRef.current.scrollHeight);
    }

    manuallyAppendMessage = message => {
        this.setState({ messages: this.state.messages.concat(message) });
        this.scrollMessages();
    }

    render = () => {
        return (
            <div className="chat">

                <ChatMessages
                    messages={this.state.messages}
                    me={this.props.user}
                    admin={this.props.admin}
                    messagesRef={this.state.messagesRef}
                    token={this.props.token} />

                <ChatInput
                    token={this.props.token}
                    me={this.props.user}
                    admin={this.props.admin}
                    manuallyAppendMessage={this.manuallyAppendMessage} />

            </div>
        )
    }
}