import React from 'react'
import YearModal from './YearModal'

const modalId = dp => {
    return `dp-modal-${dp.id}`;
}

const YearCard = ({ draftPreference, handleDraftPreferenceSelect, selected }) => {
    return (
        <div>
            <div
                onClick={() => handleDraftPreferenceSelect(draftPreference)}
                style={{ cursor: 'pointer' }}
                className={`card card-year ${selected === draftPreference.id ? 'selected' : ''}`}
                data-toggle='modal'
                data-target={`#${modalId(draftPreference)}`}>

                {
                    !draftPreference.userDraftPreferencePresent ? (
                        <div className="activate">
                            ACTIVATE
                        </div>
                    ) : null
                }

                <div className="card-top">
                    <div className="label">
                        {draftPreference.name.toUpperCase()}
                    </div>
                </div>

            </div>
            <YearModal
                draftPreference={draftPreference}
                modalId={modalId(draftPreference)} />
        </div>
    )
}

export default YearCard
