import React from 'react'
import PersonalBreakOrder from './PersonalBreakOrder'

const PersonalBreakOrdersContainer = ({ orders, user }) => {
    if (!orders.length) {
        return null
    }

    return (
        <div className="live-personal-breaks">
            <h4>Personal Break Orders</h4>
            {
                orders.map(order => {
                    return (
                        <PersonalBreakOrder
                            key={order.id}
                            order={order}
                            mine={user === order.user_id} />
                    )
                })
            }
        </div>
    )
}

export default PersonalBreakOrdersContainer