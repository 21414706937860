import React from "react"
import axios from 'axios'
import arrayMove from 'array-move'
import SortableTeamList from "./SortableTeamList"
import FormInputs from "./FormInputs"

export default class Form extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sports: [],
            selectedSportId: this.props.draft_preference.sport_id || '',
            teams: this.props.teams
        }
    }

    componentDidMount() {
        axios.get('/sports.json').then((response) => {
            this.setState({ sports: response.data })
        })
    }

    handleSportSelect = (e) => {
        const id = e.target.value

        this.setState({ selectedSportId: id })

        axios.get(`/admin/sports/${id}/teams.json`).then((response) => {
            this.setState({ teams: response.data })
        })
    }

    handleSortEnd = ({ oldIndex, newIndex }) => {
        this.setState({
            teams: arrayMove(this.state.teams, oldIndex, newIndex)
        })
    }

    render() {
        return (
            <div>
                <form action={this.props.action} method={this.props.method}>

                    {/* Auth token */}
                    <input type="hidden" name="authenticity_token" value={this.props.token} />

                    {/* Team order json array */}
                    <input type="hidden" name="draft_preference[order]" value={JSON.stringify(this.state.teams.map(t => t.id))} />

                    <div className="row mb-5">
                        <div className="col-6">

                            <FormInputs
                                draft_preference={this.props.draft_preference}
                                handleSportSelect={this.handleSportSelect}
                                selectedSportId={this.state.selectedSportId}
                                sports={this.state.sports} />

                            <button role="submit" className="btn btn-success">{this.props.draft_preference.id ? 'Update' : 'Create'} Draft Preference</button>
                        </div>
                        <div className="col-6">
                            <p style={{ marginBottom: '.5rem' }}>Select a sport and drag teams to set the sort order</p>

                            <SortableTeamList
                                onSortEnd={this.handleSortEnd}
                                teams={this.state.teams} />

                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
