import React from 'react'
import PendingBreak from './PendingBreak'
import SoldOutBreak from './SoldOutBreak'

export default class DraftBreaksContainer extends React.Component {
    renderDrafts = () => {
        return this.props.breaks.map((bk, key) => {
            switch (bk.break.status) {
                case 'on_sale':
                    return (
                        <PendingBreak
                            {...bk}
                            key={bk.break.id}
                            user={this.props.user} />
                    )
                case 'sold_out':
                    return (
                        <SoldOutBreak
                            {...bk}
                            breakComplete={this.props.breakComplete}
                            user={this.props.user}
                            key={bk.break.id} />
                    )
                default:
                    return <h1 key={key}>{`Unhandled break status: ${bk.break.status}`}</h1>
            }
        });
    }

    render = () => {
        return (
            <div className="draft">
                <div className="draft-inner">
                    {this.renderDrafts()}
                </div>
            </div>
        );
    }
}
