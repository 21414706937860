import React from 'react'

const PersonalBreakHomeCard = ({ index }) => {
    return (
        <div className={`break break-${index} break-personal`}>
            <div className="break-image-container">
                <img src="/images/personal-breaks-available.jpg" alt="Boxes of cards on a shelf" className="w-100" />
            </div>
            <div className="break-below-image">
                    <div className="break-heading">
                        <h2 className="plain">Personal Breaks Available</h2>
                    </div>
                    <div className="break-controls">
                <div className="view">
                    <a href="/personal_breaks" className="btn btn-primary">Click Here to View</a>
                </div>
            </div>
            </div>
            
        </div>
    )
}

export default PersonalBreakHomeCard
