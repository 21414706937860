import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import OnSaleBreak from './OnSaleBreak'

const OnSaleBreaksSortableContainer = SortableContainer(({ breaks, token, onSaleLive }) => {
    return (
        <ul className="list-group break-list">
            {
                breaks.map((bk, index) => {
                    return (
                        <OnSaleBreak
                            iter={index}
                            key={index}
                            index={index}
                            bk={bk}
                            token={token}
                            onSaleLive={onSaleLive} />
                    )
                })
            }
        </ul>
    )
})

export default OnSaleBreaksSortableContainer
