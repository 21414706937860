import React, {useState} from 'react'
import PaymentItem from './PaymentItem';
import AuctionPaypal from './AuctionPaypal';
import MoonLoader from 'react-spinners/MoonLoader';

const AuctionPayment = (props) => {
	const [loading, setLoading] = useState(false);

	const orderTotal = props.items.reduce((total, item) => {
		return total + parseFloat(item.current_bid)
	}, 0);

	const loaderStyles = () => {
		return [
			'margin: auto',
			'margin-top: 20px',
			'margin-bottom: 20px'
		].join('; ');
	}

	const setLoader = (loadStatus) => {
		setLoading(loadStatus);
	}

	return (
		<div className="page stadium-bg">
			<div className="container-xl">
				<div className="row justify-content-center mb-4">
					<div className="col-xl-9 col-lg-10">
						<h1 className="page-header">AUCTION PAYMENT</h1>
						<p className="intro">
							Failure to complete payment for your winning bids may result
							in a permanent ban from UltimateBoxBreaks.
						</p>
						{loading && 
							<div className='text-center'>
								<MoonLoader
										color='white'
										css={loaderStyles()} />
								<p>Processing Payment...</p>
							</div>
						}
						<div className="row justify-content-center">
							{/* <button className="btn btn-primary">PAY NOW: ${orderTotal.toFixed(2)}</button> */}
							<AuctionPaypal
								items={props.items}
								user={props.user}
								setLoader={setLoader}
								token={props.token}
								myAuctionsPath={props.my_auctions_path}
								processOrderPath={props.process_order_path}
							/>
						</div>
						<div className="row">
							<div className="col">
								<table className="auction-items">
									<tbody>
										{
											props.items.map((item, index) => {
												return (
													<PaymentItem
														key={index}
														item={item}
													/>
												)
											})
										}
										<tr className="auction-item">
											<td className="auction-item-logo"></td>
											<td className="auction-item-details"></td>
											<td className="auction-item-meta"></td>
											<td className="auction-item-meta"></td>
											<td className="auction-item-actions">
												TOTAL DUE: {orderTotal.toFixed(2)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

export default AuctionPayment;