import React from 'react'
import OnSaleBreaksSortableContainer from './OnSaleBreaksSortableContainer'
import arrayMove from 'array-move'
import axios from 'axios'

export default class OnSaleBreaksContainer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            breaks: this.props.breaks
        }
    }

    handleSortEnd = (({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) {
            console.log('Same order detected, skipping save.');
            return;
        }

        const newOrder = arrayMove(this.state.breaks, oldIndex, newIndex)

        axios.post('/admin/standard_breaks/set_break_orders.json', {
            authenticity_token: this.props.token,
            breaks: newOrder.map((bk, index) => {
                return {
                    break_id: bk.id,
                    break_order: index + 1
                };
            })
        });

        this.setState({ breaks: newOrder })
    })

    render = () => {
        return (
            <OnSaleBreaksSortableContainer
                distance={10}
                onSortEnd={this.handleSortEnd}
                breaks={this.state.breaks}
                token={this.props.token}
                onSaleLive={this.props.on_sale_live} />
        )
    }
}
