import React from 'react'
import axios from 'axios'
import DraftBreaksContainer from './DraftBreaksContainer'
import Cable from '../../lib/Cable'

export default class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            breaks: []
        };
    }

    componentDidMount() {
        axios.get('/drafts.json').then(response => this.setState({ breaks: response.data }));

        Cable.connect('DraftChannel', {
            received: data => {

                console.log('Received new draft data');

                // the final set of breaks
                const final = data.data;

                // the break ids we currently have in state... these are the ones that should stay
                // with the user so they can watch it count down, etc...
                const currentIds = this.state.breaks.map(b => b.break.id);

                // the new set of non completed break ids sent over the cable from the backend
                const newIds = final.map(b => b.break.id);

                // map over the current ids, find the ones that are missing, and keep them so the user
                // can watch them flip and the draft process happen
                currentIds.forEach(id => {
                    if (!newIds.includes(id)) {
                        this.state.breaks.forEach(b => {
                            if (b.break.id === id) {
                                final.push(b);
                            }
                        })
                    }
                });

                this.setState({ breaks: final });
            }
        });
    }

    breakComplete = c => {
        this.setState({
            breaks: this.state.breaks.map(bundle => {
                if (bundle.break.id === c.id) {
                    return {
                        ...bundle,
                        break: {
                            ...bundle.break,
                            complete: true
                        }
                    };
                } else {
                    return bundle;
                }
            })
        });
    }

    render() {
        return (
            <div className="page" id="register">
                <div className="container-xl">
                    <div className="row justify-content-center mb-4">
                        <div className="col col-md-7">
                            <h1 className="page-header">Active Drafts</h1>
                            <div className='text-center mb-3'>
                                <a href="/breaks/completed_drafts_today" className="btn btn-primary text-center">Completed Drafts</a>
                            </div>
                            {/* <div className="draft-filters">
                                <form>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                        <label className="form-check-label" htmlFor="inlineCheckbox1">Only show me</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" />
                                        <label className="form-check-label" htmlFor="inlineCheckbox2">Only show pending trades &amp; offers</label>
                                    </div>
                                </form>
                            </div> */}
                        </div>
                    </div>
                </div>
                <DraftBreaksContainer
                    breaks={this.state.breaks}
                    user={this.props.user}
                    breakComplete={this.breakComplete} />
            </div>
        );
    }
}
