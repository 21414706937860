import React from 'react'
import Index from './Index';
import axios from 'axios'
import Cable from '../../lib/Cable'

export default class BreaksContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sports: [],
            personalBreaks: false,
            auctions: false,
            loaded: false
        };
    }

    componentDidMount() {
        axios.get('/breaks.json').then(response => {
            this.setState({
                sports: response.data,
                loaded: true
            });
        });

        // connect to the breaks channel and update state when new data is available
        Cable.connect('BreaksChannel', {
            received: data => {
                console.log('Updated break data: ', data);
                this.setState({ sports: data.data });
            }
        });

        /**
         * When we are on the homepage, we also render out the breaks index component (buy screen),
         * which also includes personal breaks along with normal breaks. Therefore, whenever we have personal
         * breaks we need to load them up and connect to the personal breaks channel
         */
        if (this.props.show_personal_breaks) {
            axios.get('/personal_breaks.json').then(response => {
                this.setState({ personalBreaks: response.data.length > 0 });
            });

            Cable.connect('PersonalBreaksChannel', {
                received: data => {
                    console.log('Updated personal break data: ', data);
                    this.setState({ personalBreaks: data.data.length > 0 });
                }
            });
        }

        if (this.props.show_auctions) {
            axios.get('/auctions.json').then(response => {
                this.setState({ auctions: response.data.length > 0 });
            });

            Cable.connect('AuctionsChannel', {
                received: data => {
                    console.log('Updated auctions data: ', data);
                    this.setState({ auctions: data.data.length > 0 });
                }
            });
        }
    }

    breaks = () => {
        let breaks = [];

        this.state.sports.forEach(s => s.breaks.forEach(b => breaks.push(b)));

        return breaks;
    }

    render = () => {
        return (
            <Index
                breaks={this.breaks()}
                userSignedIn={this.props.user_signed_in}
                user={this.props.user}
                hideHeader={this.props.hide_header}
                token={this.props.token}
                header='Buy Spots'
                personalBreaks={this.state.personalBreaks}
                profileComplete={this.props.profile_complete}
                breakDetailsModalId={this.props.break_details_modal_id}
                showEmpty={this.props.show_empty}
                loaded={this.state.loaded}
                auctions={this.state.auctions} />
        )
    }
}
