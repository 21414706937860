import React from 'react'
import axios from 'axios'
import Cable from '../../lib/Cable'
import Index from '../breaks/Index'

export default class PersonalBreaksContainer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            breaks: [],
            loaded: false
        }
    }

    componentDidMount() {
        axios.get('/personal_breaks.json').then(response => {
            this.setState({
                breaks: response.data,
                loaded: true
            });
        });

        Cable.connect('PersonalBreaksChannel', {
            received: ({ data }) => {
                this.setState({ breaks: data })
                // console.log('Updated personal break data: ', data);
                // wait 5 seconds before trying to set the new personal break data
                // so it has time to upload images to s3, etc... otherwise the image
                // shows up as broken because the ui updates so fast through websockets lol
                // window.setTimeout(() => this.setState({ breaks: data }), 5000)
            }
        });
    }

    render = () => {
        return (
            <Index
                breaks={this.state.breaks}
                token={this.props.token}
                user={this.props.user}
                userSignedIn={this.props.user_signed_in}
                header='Buy Personal Breaks'
                profileComplete={this.props.profile_complete}
                personal={true}
                showEmpty={this.props.show_empty}
                loaded={this.state.loaded}>

                <div>
                    <p className='intro'>Group Breaks not your thing? Now you can buy your own personal box or packs and watch them opened live ON-Demand!</p>
                    <p style={{ textAlign: 'center' }}>
                        1. Purchase your Box or Pack<br />
                        2. We'll crack it for you live on camera during the personal break broadcast<br />
                        3. We'll ship you all of the cards from the personal break. Keepin' it Simple!
                    </p>
                </div>

            </Index>
        )

    }
}
