import React from 'react'
import AuctionBreakOrder from './AuctionBreakOrder'

const AuctionBreakOrdersContainer = (props) => {
    if (!props.auctionBreaks.length) {
        return null;
    }

    return (
       <div className="live-personal-breaks">
            <h4>Auctions</h4>
            {
                props.auctionBreaks.map(auctionBreak => {
                    return (
                        <AuctionBreakOrder
                            key={auctionBreak.id}
                            handleClick={props.handleBreakClick}
                            selected={auctionBreak.id === props.activeBreak}
                            auctionBreak={auctionBreak} />
                    )
                })
            }
        </div>
    )
}

export default AuctionBreakOrdersContainer;