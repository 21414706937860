import React from 'react'
import moment from 'moment'
import CountingDownBreak from './CountingDownBreak'
import RandomizingBreak from './RandomizingBreak'
import AssigningTeamsBreak from './AssigningTeamsBreak'

export default class SoldOutBreak extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            /**
             * The countdown timer in seconds, starting from 30 and subtracting
             * however long ago the break was sold out
             */
            seconds: 30 - this.soldOutSecondsAgo(),

            /**
             * The timer that runs to decrement the seconds every second. Once this
             * gets below 1 second it will remove the interval and stop counting
             * since at that point it's considered complete
             */
            timer: window.setInterval(() => {
                if (this.state.seconds < 0) {
                    window.clearInterval(this.state.timer);
                    return;
                }

                this.setState({
                    seconds: this.state.seconds - 1
                });

            }, 1000),
            shuffled: false
        }
    }

    setShuffled = () => {
        this.setState({ shuffled: true });
    }

    soldOutSecondsAgo = () => {
        const soldOut = moment(this.props.break.sold_out_at);
        const now = moment();
        const diff = now.diff(soldOut, 'seconds');

        return diff;
    }

    render = () => {
        if (this.state.seconds < 0) {
            if (this.props.break.complete || this.state.shuffled) {
                return (
                    <AssigningTeamsBreak {...this.props} />
                )
            } else {
                return (
                    <RandomizingBreak
                        {...this.props}
                        setShuffled={this.setShuffled} />
                )
            }
        } else {
            return (
                <CountingDownBreak
                    name={this.props.break.name}
                    seconds={this.state.seconds} />
            )
        }
    }
}
