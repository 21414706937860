import React from 'react'

const AuctionHomeCard = ({ index }) => {
    return (
        <div className={`break break-${index}`}>
            <div className="break-image-container">
                <img src="/images/default-auction-image.png" className="w-100" />
            </div>
            <div className="break-below-image">
                <div className="break-heading">
                    <h2 className="plain">Auctions Available</h2>
                </div>
                <div className="break-controls">
                    <div className="row details">
                        <div className="col-12">
                            <b>No more messin' around!</b>
                            <span>Bid on the exact team or player you want!</span>
                        </div>
                    </div>    
                    <div className="view">
                        <a href="/auctions" className="btn btn-primary">Click Here to View</a>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default AuctionHomeCard
