import React from 'react';
import AuctionItem from "./AuctionItem";

const AuctionItemsContainer = (props) => { 

	const renderAuctionItems = () => {
		return (
			props.auctionItems.map((auctionItem, index) => {
				let isFiltered = props.filteredItems.includes(auctionItem) ? true : false;
				
				return <AuctionItem
					key={auctionItem.id}
					auction={props.auction}
					auctionItem={auctionItem}
					isFiltered={isFiltered}
					token={props.token}
					user={props.user}
					userSignedIn={props.userSignedIn}
					bids={props.bids}
					itemReminders={props.itemReminders}
					handleSort={props.handleSort} />
			})
		)
	}

	return (
		renderAuctionItems()
	)
}

export default AuctionItemsContainer