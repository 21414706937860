import React from 'react'
import FlippingTeamCard from './FlippingTeamCard';

export default class AssigningTeamsBreak extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            entries: this.props.entries,
            interval: window.setInterval(this.flip, 1000),
            flipIndex: 0,
            completed: this.props.break.complete || false
        };
    }

    flip = () => {
        if (this.state.flipIndex === 0) {
            this.props.breakComplete(this.props.break);
        }

        this.setState({
            flipIndex: this.state.flipIndex + 1,
            entries: this.state.entries.map((entry, index) => {
                if (index === this.state.flipIndex) {
                    return {
                        ...entry,
                        flipped: true
                    };
                } else {
                    return entry;
                }
            })
        });

        if (this.state.flipIndex === this.state.entries.length) {
            // stop trying to fip cards since we've flipped all of them
            window.clearInterval(this.state.interval);
            // wait a moment and set the status as completed so we can remove the 'assigning teams' text
            window.setTimeout(() => this.setState({ completed: true }), 1000);

            // this.props.breakComplete(this.props.break);
        }
    }

    render = () => {
        return (
            <div className="draft-col cards card-col">
                <h2 className="mt-0 flip-trigger">{this.props.break.name}</h2>

                <div className="card-stack">
                    {
                        !this.state.completed ? (
                            <div className="spots-remaining text-center digital red-glow mb-4">
                                {this.props.break.draft_type === 'random_hit' ? 'Revealing Order' : 'Assigning Teams'}
                            </div>
                        ) : null
                    }
                    <div className="container-xl">
                        {
                            this.state.entries.map((entry, key) => {
                                return (
                                    <FlippingTeamCard
                                        key={entry.entry.id}
                                        entry={entry}
                                        user={this.props.user}
                                        sport={this.props.sport}
                                        draft_type={this.props.break.draft_type}
                                        complete={this.props.break.complete} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}
