import React, { useEffect, useState } from 'react'
import axios from 'axios'

import AuctionBidModalTimeRemaining from './AuctionBidModalTimeRemaining'
import AuctionItemLogo from './AuctionItemLogo'
import TimeSinceLastBid from './TimeSinceLastBid'

const AuctionBidModal = props => {

    const [bid, setBid] = useState("");
    const [bidResponse, setBidResponse] = useState(null);
    const [bidSuccess, setBidSuccess] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    useEffect(() => {
        /**
         * set the input's default value to the next minimum required bid amount instead of 0
         */
        let increment = 0
        const currentBid = parseFloat(props.auctionItem.current_bid);
        const startingBid = parseFloat(props.auctionItem.starting_bid_amount);

        if (currentBid) {
            if (currentBid >= 100) {
                increment = 5;
            } else {
                increment = 1;
            }
        }
        else {
            if (startingBid >= 100) {
                increment = 5;
            } else {
                increment = 1    
            }
        }
        
        if (props.auctionItem.current_bid) {
           setBid(
               parseFloat((currentBid + increment).toFixed(2))
               )
        } 
        else {
           setBid(
               parseFloat((startingBid + increment).toFixed(2))
           )
        }
    }, [props.auctionItem.starting_bid_amount]);

    const increment = () => {
        let increment = 0
        const currentBid = parseFloat(props.auctionItem.current_bid);
        const startingBid = parseFloat(props.auctionItem.starting_bid_amount);

        if (currentBid) {
            if (currentBid >= 100) {
                increment = 5;
            } else {
                increment = 1;
            }
        }
        else {
            if (startingBid >= 100) {
                increment = 5;
            } else {
                increment = 1    
            }
        }
        return increment;
    };

    const submit = () => {
        const bodyFormData = new FormData();
        const maxBid = parseFloat(bid);

        bodyFormData.append('bid[max_bid]', maxBid);
        bodyFormData.append('bid[item_id]', props.auctionItem.id);
        bodyFormData.append('bid[user_id]', props.user.id);
        bodyFormData.append('authenticity_token', props.token);

        axios({
            method: "post",
            // url: `${props.bidPath}.json`,
            url: `/auctions/${props.auction.id}/items/${props.auctionItem.id}/bids.json`,
            data: bodyFormData,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        }).then((response) => {
            setBidResponse(response.data.message);
            setBidSuccess(response.data.success);
            // if (response.data.success) {
            //     alert(response.data.message);
            //     window.location.reload();
            // }
            // else {
            //     alert(response.data.message);
            //     switch (response.data.status) {
            //         case "server_error":
            //             window.location.reload();
            //             break;
            //         case "expired":
            //             window.location.reload();
            //             break;
            //         case "not_on_sale":
            //             window.location.assign("/")
            //             break;
            //         default:
            //             break;
            //     }               
            // }
        })
    }
    
    const renderSubmitBtn = () => {
        if (props.userSignedIn && props.user && props.user.status === "active" && acceptedTerms) {
            return (
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={submit}>
                    Submit Bid
                </button>

            )
        } else if (!acceptedTerms) {
            return (
                <button
                    type="button"
                    className="btn btn-warning">
                    You must accept the terms to place bids
                </button>

            )
        }
        else {
            return <a href="/users/sign_in">Sign In to Place a Bid</a>
        }
    }

    const changeHandler = e => {
        const cleanBid = e.target.value ? parseInt(e.target.value.replace(/[^\d]/g, '')) : 0;
        setBid(cleanBid);
    }

    const incrementHandler = e => {
        if (e.target.name === "increment") {
            const newBid = bid + 1;
            setBid(newBid);
        }
        else if (e.target.name === "decrement") {
            // if bid's already 0 then don't decrement into the negatives
            const newBid = bid ? bid - 1 : 0;
            setBid(newBid);
        }
    }

    const showBidResponse = () => {
        if (bidResponse) {
            return (
                <div className={`bid-response ${bidSuccess ? "success" : "fail"}`}>
                    {bidResponse}
                </div>
            )
        }
    }

    const clearData = () => {
        setAcceptedTerms(false);
        setBidResponse(null);
        setBidSuccess(false);
    }

    return (
		<div
            className={`modal dark fade break-details`}
            // className={`modal dark break-details`}
            // style={ { display: 'block' } }
            id={props.modalId}>

            <div className="modal-dialog modal-xl modal-dialog-scrollable auction-modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>SUBMIT YOUR BID</h2>
                        {/* <h2 className="modal-title mb-0 pb-0">{props.auctionItem.title}</h2> */}
                        <button
                            type="button"
                            title='Close details'
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={clearData}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    
                    <div className="modal-body auction-modal-body">
                        <div className="row">
                            <div className="col-md-4 auction-modal-left-panel d-none d-lg-block">
                                <div className="left-panel-title">TERMS:</div>
                                <p className="mt-1">Please bid responsibly.</p>
                                <ul>
                                    <li>MAX BID: enter the maximum amount you'd like to pay for the item and select Submit bid. We'll bid in increments on your behalf to keep you in the lead but only up to your limit.</li>
                                    <li>Any unpaid auctions before the start of the live break will result in a ban from the website and forfeiture of the cards from said break.</li>
                                    <li>All auctions won in a 24hr period can be paid at once inside your My Auctions Page of your profile.</li>
                                    <li>Any unsold teams or players will not be offered up again for purchase.</li>
                                    <li>Break Times are subject to change.</li>
                                </ul>
                            </div>

                            <div className="col-md-12 col-lg-8">
                                <div className="time-remaining sm-auction-text">
                                    <AuctionBidModalTimeRemaining auctionItem={props.auctionItem} />
                                </div>

                                <div className="row mt-2">
                                    <div className="col-12 center-text-lg-down">
                                        <div className="item-logo">
                                            <AuctionItemLogo auctionItem={props.auctionItem} auction={props.auction} auctionModal={true} />
                                            <div className="auction-item-title align-items-center">{props.auctionItem.title}</div>
                                        </div>
                                    </div>
                                </div>

                                <hr className="mt-3" />

                                <div className="row bid-info">
                                    <div className="col-lg-2 col-md-3 left d-flex align-items-end">
                                        CURRENT BID:
                                    </div>
                                    <div className="col-lg-10 col-md-9 right d-flex align-items-end">
                                        ${props.auctionItem.current_bid ?
                                            parseFloat(props.auctionItem.current_bid).toFixed(2) : parseFloat(props.auctionItem.starting_bid_amount).toFixed(2)}
                                    </div>
                                </div>

                                <div className="row bid-info">
                                    <div className="col-lg-2 col-md-3 left d-flex align-items-end">
                                        NEXT MIN BID:
                                    </div>
                                    <div className="col-lg-10 col-md-9 right d-flex align-items-end">
                                        ${props.auctionItem.current_bid ?
                                            (parseFloat(props.auctionItem.current_bid) + increment()).toFixed(2) : (parseFloat(props.auctionItem.starting_bid_amount) + increment()).toFixed(2) }
                                    </div>
                                </div>

                                <div className="row bid-info">
                                    <div className="col-lg-2 col-md-3 left d-flex align-items-end">
                                        LAST BID:
                                    </div>
                                    <div className="col-lg-10 col-md-9 right d-flex align-items-end">
                                        {props.auctionItem && <TimeSinceLastBid auctionItem={props.auctionItem} bids={props.bids}/>}
                                    </div>
                                </div>

                                <hr className="mt-4" />

                                <div className="bid-section center-text-sm-down">
                                    <div className="your-bid">Your Max Bid</div>
                                    <input className="money-input" disabled value={"$"}/>
                                    <input
                                        className="bid-input"
                                        type="tel"
                                        value={bid}
                                        onChange={changeHandler} />
                                    <input className="cents-input" disabled value={".00"}/>
                                    <button className="btn btn-sm inc-dec-buttons ml-3 hidden-sm-down" onClick={incrementHandler} name="decrement">-</button>
                                    <button className="btn btn-sm inc-dec-buttons hidden-sm-down" onClick={incrementHandler} name="increment">+</button>
                                </div>

                                <div className="row d-sm-none mt-2">
                                    <div className="col-6">
                                        <button className="btn btn-sm inc-dec-buttons" onClick={incrementHandler} name="decrement">-</button>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-sm inc-dec-buttons" onClick={incrementHandler} name="increment">+</button>
                                    </div>
                                </div>

                                <div className="bid-response">
                                    {showBidResponse()}
                                </div>

                                <div className="form-group mt-3">
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={acceptedTerms}
                                            checked={acceptedTerms}
                                            onChange={(e) => setAcceptedTerms(e.target.checked)} />
                                        &nbsp;I accept these terms
                                    </label>
                                </div>

                            </div>

                            <div className="col-12 d-lg-none">
                                <div className="mt-2 left-panel-title">TERMS:</div>
                                <p className="mt-0 left-panel-title">Please bid responsibly.</p>
                                <ul>
                                    <li>MAX BID: enter the maximum amount you'd like to pay for the item and select Submit bid. We'll bid in increments on your behalf to keep you in the lead but only up to your limit.</li>
                                    <li>Any unpaid auctions before the start of the live break will result in a ban from the website and forfeiture of the cards from said break.</li>
                                    <li>All auctions won in a 24hr period can be paid at once inside your My Auctions Page of your profile.</li>
                                    <li>Any unsold teams or players will not be offered up again for purchase.</li>
                                    <li>Break Times are subject to change.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            style={{ background: "#0d0d0d"}}
                            type="button"
                            title='Close details'
                            className="btn btn-secondary"
							data-dismiss="modal"
                            onClick={clearData}>
							Close
                        </button>
                        {renderSubmitBtn()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuctionBidModal;