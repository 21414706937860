import React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import Team from '../../lib/Team';
import MoonLoader from 'react-spinners/MoonLoader';

const SortableTeamCard = SortableElement(({ team, selectedSport }) => {
    return (
        <div className="card card-team card-drag slim" key={team.id}>
            <div className="card-top">
                <div className="sort-num">{team.index + 1}</div>

                <div className="logo">
                    <img src={Team.imagePath(team, selectedSport)} alt={team.name} />
                </div>

                <div className="label">{team.name}</div>

                {
                    team.saving ? (
                        <MoonLoader size={15} />
                    ) : null
                }

                {
                    team.saved ? (
                        <img src='/images/check.svg' height='20px' />
                    ) : null
                }

                <div className="grip" style={{ cursor: 'grab' }} title='Drag and drop your teams'><i className="fas fa-grip-vertical"></i></div>
            </div>
        </div>
    )
})

export default SortableTeamCard
