import React, { useEffect, useState } from 'react'
import moment from 'moment';

const AuctionBidModalTimeRemaining = (props) => {

	const [days, setDays] = useState(null);
	const [hours, setHours] = useState(null);
	const [minutes, setMinutes] = useState(null);
	const [seconds, setSeconds] = useState(null);

	useEffect(() => {
		setTimeout(() => {
			calculateTimeLeft();
		}, 1000);

		if (window.calculateInterval) {
			window.clearInterval(window.calculateInterval)
		}
		
		window.calculateInterval = window.setInterval(calculateTimeLeft(), 1000)
	}, [days, hours, minutes, seconds]);

	const calculateTimeLeft = () => {
		const now = moment()
		const next = props.auctionItem ? moment(props.auctionItem.offset_date) : now
		const diff = next.diff(now)
		const duration = moment.duration(diff)

		if (duration.days() <= 0 && duration.hours() <= 0 && duration.minutes() <= 0 && duration.seconds() <= 0) {
      // stop the calculation so it stops at 0
      window.clearInterval(window.calculateInterval)
		}

		setDays(duration.days());
		setHours(duration.hours());
		setMinutes(duration.minutes());
		setSeconds(duration.seconds());
	}

  const formatRemaining = (time, increment) => {
    if (time > 0) {
      return `${time}<span style="font-size: .5em"> ${increment}</span> `
    } else {
      return '';
    }
  }

  const formatTime = () => {
    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      return "AUCTION HAS ENDED"
		} else {
      let formattedTime = 'AUCTION ENDS IN ';
      formattedTime += `${formatRemaining(days, 'D')}`
      formattedTime += `${formatRemaining(hours, 'H')}`
      formattedTime += `${formatRemaining(minutes, 'M')}`
      formattedTime += `${formatRemaining(seconds, 'S')}`

      return formattedTime;
    }
  }

	return (
		<span className="auction-item-meta-detail">
      <div dangerouslySetInnerHTML={{__html: formatTime()}} />
    </span>
  )
}

export default AuctionBidModalTimeRemaining;