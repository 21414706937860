import React from 'react'

const breakStatus = status => {
    return status.split('_').join(' ');
}

const DraftOrderBreak = ({ bk, handleClick, selected }) => {
    return (
        <div
            style={{ cursor: 'pointer' }}
            className={`card card-year ${selected ? 'selected' : ''}`}
            onClick={() => handleClick(bk)}>

            {bk.type === 'PersonalBreak' ? 'Personal Break - ' : ''} {bk.name} ({breakStatus(bk.status)})

        </div>
    )
}

export default DraftOrderBreak
