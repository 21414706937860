import React from 'react'

const SportTabs = (props) => {
    if (!props.sports.length) {
        return null;
    }
    return (
        <div className="tab-nav">
            <ul>
                {
                    props.sports.map((sport) => {
                        return (
                            <li
                                onClick={() => props.handleSportSelect(sport.id)}
                                style={{ cursor: 'pointer' }}
                                key={sport.id}
                                className={sport.id === props.selectedSport ? 'selected' : ''}>

                                {sport.name.toUpperCase()}

                            </li>
                        )
                    })
                }
            </ul>
        </div>
    );
}

export default SportTabs