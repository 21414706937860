/**
 * Helper class for team related stuff
 */
export default class Team {
    /**
     * Get the icon/image path for a team
     *
     * @param {*} team the team object from the API
     * @param {*} sport the sport object from the API
     */
    static imagePath = (team, sport) => {
        // e.g. St. Louis Rams -> st-louis-rams
        const formattedName = team.name.split(' ').map(p => p.toLowerCase().replace('.', '')).join('-');
        // our db uses 'Football' etc for sport names, not league names, so we have to convert it here
        const league = ({ 'Football': 'nfl', 'Baseball': 'mlb', 'Basketball': 'nba' })[sport.name];

        return `/images/teams/${league}/${league}-${formattedName}.png`;
    }
}