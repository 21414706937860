import React from 'react'
import axios from 'axios'
import DraftOrderEntry from './DraftOrderEntry'
import Cable from '../../lib/Cable'
import sortObjects from 'sort-objects-array'
import DraftOrderBreaksContainer from './DraftOrderBreaksContainer'
import PersonalBreakOrdersContainer from './PersonalBreakOrdersContainer'
import AuctionBreakOrdersContainer from './AuctionBreakOrdersContainer'

export default class DraftOrderContainer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            /**
             * All the >= sold out breaks for tonight
             */
            breaks: [],
            /**
             * The id of the break the user currently clicked on and is viewing
             */
            activeBreak: null,
            /**
             * The entries associated with the break the user clicked on and is viewing
             */
            entries: [],
            /**
             * Personal break orders
             */
            personalBreakOrders: [],
            /**
             * Auction breaks
             */
            auctionBreaks: []
        }
    }

    componentDidMount(){
        /**
         * As soon as the component mounts, fetch all the live breaks
         */
        axios.get('/breaks/live.json').then(({ data }) => {
            this.setState({
                breaks: data.breaks,
                personalBreakOrders: data.orders,
                auctionBreaks: data.auction_breaks
            })
        })

        /**
         * Connect to the live breaks cable for when the admin updates any of the break statuses
         */
        Cable.connect('LiveBreaksChannel', {
            received: ({ data }) => {
                this.setState({
                    breaks: data.breaks,
                    personalBreakOrders: data.orders,
                    auctionBreaks: data.auction_breaks
                })
            }
        })
    }

    /**
     * When a user clicks on an individual break, we set that break as active and fetch that
     * break's entries from the server
     */
    handleBreakClick = (bk, breakType="draft-order") => {
        this.setState({ activeBreak: bk.id })
        switch (breakType) {
            case "auction":
                axios.get(`/auctions/${bk.id}/items.json`).then(({ data }) => {
                    this.setState({ entries: data })
                });
                break;
                
            default:
                axios.get(`/breaks/${bk.id}/entries.json`).then(({ data }) => {
                    this.setState({ entries: data })
                });
                break;
        }
    }

    activeBreakIsPersonal() {
        if (!this.state.activeBreak) {
            return
        }

        const active = this.state.breaks.filter(bk => bk.id === this.state.activeBreak)[0]
        return active?.type === 'PersonalBreak';
    }

    render() {
        let entries = this.state.entries;

        if (this.activeBreakIsPersonal()) {
            entries = entries.map(entry => {
                return {
                    ...entry,
                    id: entry.entry.id
                };
            })

            entries = sortObjects(entries, 'id')
        }

        return (
            <div className="row">
                <h3>Tonight's breaks - Click to view draft order</h3>
                <div className="col-md-7">

                    <strong>

                        <DraftOrderBreaksContainer
                            breaks={this.state.breaks}
                            activeBreak={this.state.activeBreak}
                            handleBreakClick={this.handleBreakClick} />

                    </strong>

                    <PersonalBreakOrdersContainer
                        orders={this.state.personalBreakOrders}
                        user={this.props.user} />
                    
                    <AuctionBreakOrdersContainer
                        auctionBreaks={this.state.auctionBreaks}
                        activeBreak={this.state.activeBreak}
                        handleBreakClick={this.handleBreakClick} />

                </div>
                <div className="col-md-5">
                    {
                        entries.map(e => {
                            return (
                                <DraftOrderEntry
                                    key={e.id}
                                    entry={e}
                                    mine={this.props.user === e.user_id} />
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}
