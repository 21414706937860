import React from 'react';
import axios from 'axios';
import SortableFaqList from './SortableFaqList';
import arrayMove from 'array-move';

export default class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            faqs: [],
            sorting: false
        };
    }

    componentDidMount() {
        axios.get('/admin/faqs.json').then((response) =>  {
            this.setState({ faqs: response.data });
        });
    }

    renderEmptyMessage() {
        return <h3>There are no FAQs found in the system.</h3>
    }

    handleSortEnd = ({ oldIndex, newIndex }) => {
        this.setState({
            faqs: arrayMove(this.state.faqs, oldIndex, newIndex)
        })
    }

    renderFaqs() {
        if (this.state.sorting) {
            return <SortableFaqList faqs={this.state.faqs} onSortEnd={this.handleSortEnd} />
        } else {
            return (
                <div>
                    <table className="table">
                        <colgroup>
                            <col width="auto" />
                            <col width="auto" />
                            <col width="150px" />
                        </colgroup>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Question</th>
                                <th scope="col">Answer</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.faqs.map((faq, key) => {
                                return (
                                    <tr key={key}>
                                        <th scope="row" dangerouslySetInnerHTML={{ __html: faq.question.body }}></th>
                                        <th scope="row" dangerouslySetInnerHTML={{ __html: faq.answer.body }}></th>
                                        <th scope="row"><a href={`/admin/faqs/${faq.id}/edit`}>Edit</a> | <a href={`/admin/faqs/${faq.id}`} onClick={this.deleteFaq} className='red'>Delete</a></th>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
            );

        }
    }

    deleteFaq = (e) => {
        e.preventDefault();

        if (!window.confirm('Are you sure you want to delete this FAQ? This action cannot be undone.')) {
            return false;
        }

        axios.delete(`${e.target.href}.json?authenticity_token=${this.props.token}`).then((response) => {
            window.location.reload();
        });
    }

    enableSort = () => {
        this.setState({ sorting: true });
    }

    saveSort = () => {
        console.log('save sorting...');

        const newOrder = this.state.faqs.map((faq, key) => {
            return {
                id: faq.id,
                sort_order: key + 1
            };
        });

        axios.post('/admin/faqs/sort', {
            authenticity_token: this.props.token,
            sort_order: newOrder
        }).then((response) => {
            if (response.data.success) {
                window.location.reload();
            } else {
                alert(response.data.message);
            }
        });

        console.log(newOrder);
    }

    cancelSort = () => {
        this.setState({ sorting: false });
    }

    render() {
        return (
            <div className="container-fluid">
                <h1>FAQs</h1>
                <a href="/admin/faqs/new" className="btn btn-primary">Create</a>
                {
                    !this.state.sorting && this.state.faqs.length ? (
                        <>
                            &nbsp;
                            <button className="btn btn-warning" onClick={this.enableSort}>Sort FAQs</button>
                        </>
                    ) : null
                }
                {
                    this.state.sorting && (
                        <>
                            &nbsp;
                            <button className="btn btn-success" onClick={this.saveSort}>Save FAQ Sorting</button>
                            &nbsp;
                            <button className="btn btn-danger" onClick={this.cancelSort}>Cancel FAQ Sorting</button>
                        </>
                    )
                }
                <hr />
                {this.state.faqs.length ? this.renderFaqs() : this.renderEmptyMessage()}
            </div>
        );
    }
}
