import React, {useEffect} from 'react';
import axios from 'axios';

const AuctionPaypal = (props) => {

    useEffect(() => {
        const items = props.items;
        const setLoader = props.setLoader;
        const orderDescription = "";
        const orderTotal = items.reduce((total, item) => {
            return total + parseFloat(item.current_bid)
        }, 0);
        // const item_ids = items.map(item => item.id);

        // build the order description to pass into paypal
        items.forEach(item => {
            const title = item.title;
            const amount = Number.parseFloat(item.current_bid).toFixed(2);
            orderDescription + `${title}: ${amount}, `;
        });

		paypal.Buttons({
            createOrder: function(data, actions) {
                return actions.order.create({
                    // purchase_units: items.map(item => {
                    //     return {
                    //         reference_id: `auction_item_${item.id}`,
                    //         description: item.title,
                    //         amount: {
                    //             value: Number.parseFloat(item.current_bid).toFixed(2)
                    //         }
                    //     }
                    // }),
                    purchase_units: [{
                        description: orderDescription,
                        amount: {
                            value: orderTotal
                        }
                    }],
                    application_context: {
                        shipping_preference: "NO_SHIPPING"
                    }
                });
            },
            onApprove: function(data, actions) {
                console.log('Approved: ', { data: data, actions: actions });
                setLoader(true);

                actions.order.capture().then(function(details) {
                    console.log('Capture details: ', details);
                    
                    axios.post(`${props.processOrderPath}`, {
                        authenticity_token: props.token,
                        auction_order: {
                            // item_ids: item_ids,
                            // user_id: props.user.id,
                            paypal_transaction: details.id,
                            price_total: orderTotal,
                        }
                    }).then((response) => {
                        if (response.data.success) {
                            window.location.assign(`${props.myAuctionsPath}`);
                        } else {
                            setLoading(false);
                            alert(response.data.message);
                            window.location.assign(`${props.myAuctionsPath}`);
                        }
                    });
                });
            }
        }).render(`#paypal-button-container-${props.user.id}`);
	}, []);

    return (
        <div id={`paypal-button-container-${props.user.id}`}></div>
    );
};

export default AuctionPaypal;