import React from "react";

const AuctionbBreakOrder = (props) => {

    const breakStatus = status => {
        return status.split('_').join(' ');
    }

    return (
        <div
            style={{ cursor: 'pointer' }}
            className={`card card-year ${props.selected ? 'selected' : ''}`}
            onClick={() => props.handleClick(props.auctionBreak, "auction")}
        >
            {props.auctionBreak.name} ({breakStatus(props.auctionBreak.status)})
        </div>
    )
}

export default AuctionbBreakOrder;