import React, { useEffect, useState } from 'react';
import AuctionBidModal from './AuctionBidModal';
import axios from 'axios'
import AuctionBidHistory from './AuctionBidHistory';
import Cable from '../../lib/Cable';
import AuctionCountDown from './AuctionCountDown';
import Team from '../../lib/Team';
import Sport from '../../lib/Sport';

const AuctionItem = (props) => { 
	const [auctionItem, setAuctionItem] = useState({});
	const [bids, setBids] = useState([]);
	const [showHistory, setShowHistory] = useState(false);
	const [auction, setAuction] = useState(props.auction);
	const [isExpired, setIsExpired] = useState(false);
   const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		// get the latest bids on for each auction item
		// axios.get(`/auctions/${props.auction.id}/items/${props.auctionItem.id}/bids.json`).then((response) => {
		// 	setBids(response.data.data)
		// });

		// const filteredBids = props.bids.filter(bid => bid.item_id === props.auctionItem.id);
		// setBids(filteredBids);

		// connect to the resource specific channel and update the item data if there's any changes from rails side
		Cable.connectResource('ItemChannel', props.auctionItem.id, {
			received: data => {
				console.log("Updated item and bids data: ", data);
				setAuctionItem(data.updated_item);
				setBids(data.updated_bids);
				props.handleSort()
			}
		});
	}, [])

   useEffect(() => {
      /**
       * we have to run this to set the bids and item state every time the item id changes
       * to prevent a memory leak bug where the browser doesn't re-render with the correct
       * bid and item data when user performs a search or filter
       */
		const filteredBids = props.bids.filter(bid => bid.item_id === props.auctionItem.id);
		setBids(filteredBids);
      setAuctionItem(props.auctionItem);

      // reminder icon also needs to be checked everytime the item id changes
      checkForReminder();
	}, [props.auctionItem.id]);

	const modalId = () => {
		// this sets the id so the bootstrap modal knows which one to open
		return `break-details-${auctionItem.id}`;
	};

	const toggleHistory = () => {
		setShowHistory(!showHistory)
	}

	const findUserBid = () => {
		let isBidder = false;
		for (const bid of bids) {
			if (bid.user_id === props.user?.id) {
				isBidder = true;
				break;
			}
		}
		return isBidder;
	}

	const bidStatusBadge = () => {
		/**
		 * If the user's id matched the user id on the auction item then they are the most recent bid winner; display the high bid badge for that user
		 * If the current user is not the most recent bid winner, check if they placed any previous bids for this item, if so then they have been out bid at some point
		 * Otherwise don't render any badges for the user
		 */
		if (auctionItem.user_id === props.user?.id) {
			let maxBid = 0;
			bids.forEach(bid => {
				if (bid.user_id === props.user?.id && parseFloat(bid.max_bid) >= maxBid) {
					maxBid = parseFloat(bid.max_bid);
				}
			});
			return <div className="auction-item-details-status badge badge-success">High Bid: ${maxBid.toFixed(2)}</div>
		}
		else if (findUserBid()) {
			return <div className="auction-item-details-status badge badge-danger">Out Bid</div>
		}
		else {
			return <div className="auction-item-details-status badge"></div>
		}
	}

	// const itemEndTime = () => {
	// 	const dateToday = new Date();
	// 	const dateFuture = new Date(auctionItem.offset);
	// 	/**
	// 	 * the total in seconds between the future date and today
	// 	 * if it's less than 0 then it's already expired so we just set delta to 0
	// 	 */
	// 	let delta = dateFuture - dateToday >= 0 ? ((dateFuture - dateToday) / 1000) : 0;

	// 	// calculate and subtract number of whole days in seconds
	// 	const days = Math.floor(delta / 86400);
	// 	delta -= days * 86400;

	// 	// calculate and subtract number of whole hours in seconds
	// 	const hours = (Math.floor(delta / 3600) % 24);
	// 	delta -= hours * 3600;

	// 	// calculate and subtract number of whole minutes in seconds
	// 	const minutes = (Math.floor(delta / 60) % 60);
	// 	delta -= minutes * 60;
		
	// 	let displayDate = `
	// 		${days > 0 ? days.toString()+"D" : ""} 
	// 		${hours > 0 ? hours.toString()+"H" : ""}
	// 		${minutes > 0 ? minutes.toString() + "M" : ""} 
	// 	`
	// 	return displayDate;
	// }
	
	const hideActions = (data) => {
		setIsExpired(data);
   }
   
   // check if the user has marked this item for a reminder
   const checkForReminder = () => {
      const foundReminder = props.itemReminders.find(reminder => reminder.item_id === props.auctionItem.id && reminder.user_id === props.user?.id && reminder.reminder_on);
      if (foundReminder) {
         setIsChecked(true);
      }
   }

   const toggleReminder = (e) => {
      // if the user isn't signed in we don't want to let them set the reminder
      if (!props.userSignedIn && !props.user?.id) {
         alert("You need to be signed in to set a reminder on this item")
      }

      // make post request to set the reminder status on this item_reminder
      axios.post(`/items/${auctionItem.id}/item_reminders/set_reminder.json`, {
         authenticity_token: props.token,
         item_reminder: {
            item_id: auctionItem.id,
            user_id: props.user.id,
            reminder_on: e.target.checked
         }
      });
      setIsChecked(e.target.checked);
   }

	const evalShowTeamLogo = (teamData, sportData, auctionSport, showLogos) => {
		if(showLogos) {
			if (teamData !== "no_data") {
				return <img src={Team.imagePath(teamData, sportData)} alt="team" />
			} else {
				// Jank logic for the weird dimensions of the NBA logo
				// We can clean this up later if we need to
				if (auctionSport === 3) {
					return <img src={Sport.imagePath(auctionSport)} alt="team" className="nba-logo-dimensions" />
				} else {
					return <img src={Sport.imagePath(auctionSport)} alt="team"/>
				}
			}
		} else {
			return <span></span>
		}
	}
	
	return (
		<>
			<tr className={`${!props.isFiltered ? "hidden" : ""} auction-item`}>
				<td className="auction-item-logo">
					{ evalShowTeamLogo(props.auctionItem.image_data.team_data, props.auctionItem.image_data.sport_data, props.auction.sport_id, props.auction.show_logos) }
				</td>
				<td className="auction-item-details">
					<div className="auction-item-details-name">{auctionItem.title}</div>
					{bidStatusBadge()}
				</td>
				<td className="auction-item-meta">
					<span className="auction-item-meta-label">
						{new Date(auctionItem.offset_date) >= new Date() ? "CURRENT BID" : "FINAL BID"}
					</span>
					<span className="auction-item-meta-detail">${auctionItem.current_bid ? parseFloat(auctionItem.current_bid).toFixed(2) : parseFloat(auctionItem.starting_bid_amount).toFixed(2)}</span>
				</td>
				<td className="auction-item-meta">
					<span className="auction-item-meta-label">Ends In:</span>
					<AuctionCountDown auctionItem={auctionItem} hideActions={hideActions} />
				</td>
				<td className="auction-item-actions">
					<label className="show-history" title="Show bid history">
						<input
							type="checkbox"
							onChange={toggleHistory}
						/>
						<div className="btn btn-outline btn-icon">
							<i className="far fa-history"/>
						</div>
					</label>
					{!isExpired && 
						<label className="get-alerts" title="Add to my watchlist">
							<input
								type="checkbox"
								checked={isChecked}
                        onChange={toggleReminder} />
							<div className="btn btn-outline btn-icon">
								<i className="far fa-eye-slash auction-alert-off"/>
								<i className="far fa-eye auction-alert-on"/>
							</div>
						</label>
					}
					{!isExpired && 
						<button
							className="btn btn-primary"
							data-toggle='modal'
							data-target={`#${modalId()}`}
							title="Place a bid" >
							PLACE YOUR BID
						</button>
					
					}
				</td>
			</tr>
			{showHistory &&
				<AuctionBidHistory
				bids={bids}
				auctionItem={auctionItem} />
			}
			<AuctionBidModal
				auction={auction}
				auctionItem={auctionItem}
				modalId={modalId()}
				token={props.token}
				user={props.user}
				userSignedIn={props.userSignedIn}
				bids={bids} />
		</>
	)
}

export default AuctionItem