import React from 'react'

const UserCards = props => {
    return props.users.map((user, key) => {
        return (
            <div className="container ml-0 mr-0 pl-0 pr-0" key={`${props.bk.id}-${user.id}-${key}`}>
                <div className="row ml-0 mr-0 pl-0 pr-0">
                    <div className="col ml-0 mr-0 pl-0 pr-0">
                        <div className={`card card-no-team card-drag ${user.id === props.user ? 'card-mine' : ''}`}>
                            <div className="card-top">
                                <div className="card-content">
                                    <div className="label">
                                        <span>{user.username} - {user.index}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
}

export default UserCards
