import React from 'react'
import PaymentSpinner from '../spinners/PaymentSpinner'
import BreakDetailsActionRow from './BreakDetailsActionRow'
import BreakDetailsBody from './BreakDetailsBody'

const showBreakDetailsOnLoad = (breakId, detailsId) => {
    return breakId === Number.parseInt(detailsId)
}

const BreakDetailsModal = props => {
    return (
        <div
            className={`modal dark fade break-details ${showBreakDetailsOnLoad(props.break.id, props.breakDetailsModalId) ? 'show' : ''}`}
            style={showBreakDetailsOnLoad(props.break.id, props.breakDetailsModalId) ? { display: 'block' } : null}
            id={props.modalId}>

            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title mb-0 pb-0">{props.break.name}</h2>
                        <button
                            onClick={() => showBreakDetailsOnLoad(props.break.id, props.breakDetailsModalId) ? window.location.assign('/') : null}
                            type="button"
                            title='Close details'
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">

                            <span aria-hidden="true">&times;</span>

                        </button>
                    </div>
                    <div className="modal-body">
                        {
                            props.loading ? (
                                <PaymentSpinner />
                            ) : (
                                    <BreakDetailsBody
                                        cost={props.break.cost_per_spot}
                                        spots={props.spotsRemaining}
                                        description={props.break.description}
                                        excludedTeams={props.excludedTeams}
                                        unitOfSale={props.break.unit_of_sale} />
                                )
                        }
                        <BreakDetailsActionRow
                            spotsRemaining={props.spotsRemaining}
                            loading={props.loading}
                            bk={props.break}
                            setLoading={props.setLoading}
                            token={props.token}
                            activeUser={props.activeUser}
                            rewardPoints={props.rewardPoints}
                            guest={props.guest}
                            profileComplete={props.profileComplete} />


                    </div>
                    <div className="modal-footer">
                        <button
                            onClick={() => showBreakDetailsOnLoad(props.break.id, props.breakDetailsModalId) ? window.location.assign('/') : null}
                            type="button"
                            title='Close details'
                            className="btn btn-secondary"
                            data-dismiss="modal">

                                Close

                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BreakDetailsModal
