import React from 'react'
import axios from 'axios'
import Team from '../../lib/Team'
import moment from 'moment'

export default class Show extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            break: {},
            entries: [],
            sport: null
        }
    }

    componentDidMount() {
        axios.get(`/breaks/${this.props.id}.json`).then(response => {
            this.setState({ ...response.data });
        });
    }

    render = () => {
        return (
            <div>
                <div className="container-xl" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row justify-content-center mb-4">
                                <div className="col-8">
                                    <h1 className="page-header">{this.state.break.name}</h1>
                                    <p>{moment(this.state.break.sold_out_at).format('MMMM Do, YYYY')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <p className="intro" dangerouslySetInnerHTML={{ __html: this.state.break.description }}></p>
                        </div>
                        <div className="row-lg-6">
                            <div className="draft-col cards card-col">
                                <div className="card-stack" style={{ textAlign: 'left' }}>
                                    {
                                        this.state.entries.map((entry, key) => {
                                            return (
                                                <div className="container-xl" key={key}>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className={`card ${this.state.break.draft_type === 'random_hit' ? 'card-no-team' : 'card-team'} card-drag ${this.props.user === entry.user.id ? 'card-mine' : null}`}>
                                                                <div className="card-top">
                                                                    <div className="position">{entry.entry.draft_order}</div>
                                                                    {
                                                                        this.state.break.draft_type === 'random_hit' ? null : (
                                                                            <div className="logo"><img src={Team.imagePath(entry.team, this.state.sport)} alt="team" /></div>
                                                                        )
                                                                    }
                                                                    <div className="card-content">
                                                                        <div className="label"><span>{entry.user.username}</span></div>
                                                                        {
                                                                            this.state.break.draft_type === 'random_hit' ? null : (
                                                                                <div className="team">{entry.team.name}</div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
