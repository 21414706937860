import React, { useEffect, useState } from 'react'
import Cable from '../../lib/Cable'
import axios from 'axios'
import AuctionCountDown from './AuctionCountDown'

const AuctionCard = (props) => {

    // state
    // const [someState, setSomeState] = useState('');

    // componentdidmount
    // useEffect(() => {
        
    // });

    const delta = () => {
        const firstItem = props.items.find(item => item.break_id === props.auction.id);
        const endDate = firstItem ? new Date(firstItem.offset_date) : new Date();
        const today = new Date();
        let delta = endDate - today > 0 ? ((endDate - today) / 1000) : 0;
        return {
            delta: delta,
            firstItem: firstItem
        };
    }

    const firstItemEndDate = () => {
        if (delta().delta >= 86400) {
            const days = Math.floor(delta().delta / 86400);
            return `NEXT ITEM ENDS IN ${days} DAY(S)`;
        }
        else if (delta().delta < 86400 && delta().delta > 3600) {
            const hours = (Math.floor(delta().delta / 3600) % 24);
            return `NEXT ITEMS ENDS IN ${hours} HOUR(S)`;
        }
        else {
            return "THERE ARE NO ITEMS ON SALE";
        }

    }

    return (
        <div className="break break-3">
            <div className="break-image-container">
                <img src={`/api/breaks/${props.auction.id}/image`} className="break-image"/>
            </div>
            <div className="break-below-image">
                <div className="break-heading mb-0">
                    <h2 className="plain">{props.auction.name}</h2>
                </div>
                <div className="break-controls">
                    <div className="row details">
                        <div className="col">
                            {/* <p>{props.auction.description}</p> */}
                            {
                                delta().delta > 0 && delta().delta <= 3600 ? 
                                    <AuctionCountDown auctionItem={delta().firstItem} isCard={true} />
                                :
                                <span style={{ fontSize: "1.2rem" }}>{firstItemEndDate()}</span>

                            }
                        </div>
                    </div>
                    <div className="view">
						{/* <button className="btn btn-primary" title="View Auction" data-toggle="modal" data-target="#break-details-9">View Auction</button> */}
						 <a href={`/auctions/${props.auction.id}`} className="btn btn-primary">View Auction</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuctionCard;