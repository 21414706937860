import React from 'react'
import SortableTeamCardContainer from './SortableTeamCardContainer';

const Teams = (props) => {
    if (!props.teams) {
        return null;
    }

    return (
        <div className="col-sm-12 col-md-6 col-sm-4 cards card-col">
            <h2 className="mt-0">SORT - {props.selectedDraftPreference}</h2>

            <SortableTeamCardContainer
                onSortEnd={props.handleSortEnd}
                teams={props.teams}
                selectedSport={props.selectedSport}
                useWindowAsScrollContainer={true} />

        </div>
    )
}

export default Teams
