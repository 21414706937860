import React, { useState, useEffect } from 'react'
import moment from 'moment'

const TimeSinceLastBid = (props) => {
  const [lastBidTime, setLastBidTime] = useState("");

  useEffect(() => {
    calculateLastBidTime(props.bids)

    const intervalId = setInterval(() => {
      calculateLastBidTime(props.bids)
    }, 10000);
  
    return () => clearInterval(intervalId);
  }, [props]);

	const calculateLastBidTime = (bids) => {
    if (bids.length > 0) {
      setLastBidTime(moment(bids[0].created_at).fromNow())
    }
    else {
        setLastBidTime("N/A");
    }
  }

	return (
    <>{lastBidTime}</>
	)
};

export default TimeSinceLastBid;