import React from 'react';
import axios from 'axios';

export default class Paypal extends React.Component {

    componentDidMount() {

        /**
         * Rails authenticity token
         */
        const token = this.props.token;

        /**
         * Break prop
         */
        const getBreak = this.props.getBreak;

        const setLoading = this.props.setLoading;

        /**
         * Item attributes
         */
        const price = () => Number.parseFloat(getBreak().cost_per_spot).toFixed(2);
        const name = () => getBreak().name;

        /**
         * The callback in order to get the quantity.
         *
         * This is passed as a callback to this component because all of the Paypal logic
         * here is ran at componentDidMount. Therefore, when the parent component updates
         * its quantity, this method will not update. Therefore, if we use initial props,
         * the quantity will never be updated dynamically. The easiest way to do that is just
         * to pass a callback from the parent. You could also make a method here to dynamically
         * get its version of props, but the parent callback can be passed in place.
         */
        const getQuantity = this.props.getQuantity;

        paypal.Buttons({
            createOrder: function(data, actions) {
                return actions.order.create({
                    purchase_units: [{
                        description: name(),
                        amount: {
                            value: price() * getQuantity()
                        }
                    }],
                    application_context: {
                        shipping_preference: "NO_SHIPPING"
                    }
                });
            },
            onApprove: function(data, actions) {
                console.log('Approved: ', { data: data, actions: actions });
                setLoading(true);
                var break_id = parseInt(getBreak().id) * 43113;
                window.location.assign(`/breaks/${break_id}/orders/${data.orderID}/confirm_transaction?q=${getQuantity()}&p=${price() * getQuantity()}`);


                // axios.post(`/breaks/${getBreak().id}/orders.json`, {
                //     authenticity_token: token,
                //     order: {
                //         price_total: price() * getQuantity(),
                //         price_per_item: price(),
                //         quantity: getQuantity()
                //     }
                // }).then(({ data }) => {
                //     if (!data.success) {
                //         alert(data.message);
                //         setLoading(false);
                //         return;
                //     }

                //     return actions.order.capture().then(function(details) {
                //         console.log('Capture details: ', details);
                //         axios.post(`/breaks/${getBreak().id}/orders/${data.order.id}/capture_transaction.json`, {
                //             authenticity_token: token,
                //             paypal_transaction: details.id
                //         }).then(() => {
                //             window.location.assign(`/breaks/${data.order.break_id}/orders/${data.order.id}`);
                //         });
                //     });
                // });

                // axios.get(`/breaks/${getBreak().id}/check_spots/${getQuantity()}.json`).then((response) => {
                //     if (response.data.available) {
                //         return actions.order.capture().then(function(details) {
                //             console.log('Capture details: ', details);

                //             axios.post(`/breaks/${getBreak().id}/orders.json`, {
                //                 authenticity_token: token,
                //                 order: {
                //                     price_total: price() * getQuantity(),
                //                     price_per_item: price(),
                //                     quantity: getQuantity(),
                //                     paypal_transaction: details.id
                //                 }
                //             }).then((response) => {
                //                 if (response.data.success) {
                //                     window.location.assign(`/breaks/${response.data.order.break_id}/orders/${response.data.order.id}`);
                //                 } else {
                //                     setLoading(false);
                //                     alert(response.data.message);
                //                 }
                //             });
                //         });
                //     } else {
                //         setLoading(false);
                //         alert('ERROR: The quantity you have selected is no longer available.');
                //     }
                // });
            }
        }).render(`#paypal-button-container-${getBreak().id}`);
    }

    render() {
        return (
            <div id={`paypal-button-container-${this.props.getBreak().id}`}></div>
        );
    }
}
