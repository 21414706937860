import { createConsumer } from "@rails/actioncable"

/**
 * Main class for ActionCable helpers.
 */
export default class Cable {
    /**
     * Connect to a channel and set up callbacks.
     *
     * @param channel The channel name to connect to.
     * @param options Object containing callback options for the various cable events
     *      connected():    callback that will run when connecting to the channel
     *      disconnected(): callback that will run when disconnecting from the channel
     *      received(data): callback that will run when new data is retrieved from the cable.
     */
    static connect(channel, options) {
        createConsumer().subscriptions.create(channel, {
            // connect to cable
            connected() {
                if (typeof options.connected === 'function') {
                    options.connected();
                } else {
                    console.log(`Connected to cable: ${channel}`);
                }
            },

            // disconnect from cable
            disconnected() {
                if (typeof options.disconnected === 'function') {
                    options.disconnected();
                } else {
                    console.log(`Disconnected from cable: ${channel}`);
                }
            },

            // receive data from cable
            received(data) {
                if (typeof options.received === 'function') {
                    options.received(data);
                } else {
                    console.log(`Unhandled data from channel: ${channel}: `, data);
                }
            }
        });
    }
    
    /**
     * Connect to a channel for specific resources.
     *
     * @param channel The channel name to connect to.
     * @param id The id of the resource
     * @param options Object containing callback options for the various cable events
     *      connected():    callback that will run when connecting to the channel
     *      disconnected(): callback that will run when disconnecting from the channel
     *      received(data): callback that will run when new data is retrieved from the cable.
     */
    static connectResource(channel, id, options) {
        createConsumer().subscriptions.create({ channel, resource_id: id }, {
            // connect to cable
            connected() {
                if (typeof options.connected === 'function') {
                    options.connected();
                } else {
                    console.log(`Connected to cable: ${channel}`);
                }
            },

            // disconnect from cable
            disconnected() {
                if (typeof options.disconnected === 'function') {
                    options.disconnected();
                } else {
                    console.log(`Disconnected from cable: ${channel}`);
                }
            },

            // receive data from cable
            received(data) {
                if (typeof options.received === 'function') {
                    options.received(data);
                } else {
                    console.log(`Unhandled data from channel: ${channel}: `, data);
                }
            }
        });
    }
}
