import React from 'react'
import MoonLoader from 'react-spinners/MoonLoader'

const defaultStyles = [
    'margin: auto',
    'margin-top: 20px',
    'margin-bottom: 20px'
].join('; ');

const Spinner = ({ loadingText }) => {
    return (
        <div className='text-center'>

            <MoonLoader
                color='white'
                css={defaultStyles} />

            <p>{loadingText}</p>

        </div>
    )
}

export default Spinner