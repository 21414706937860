import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

const SortableTeam = SortableElement(({ team }) => {
    return (
        <li
            className='list-group-item'
            style={{ background: team.primary_color, color: team.secondary_color, cursor: 'grab', userSelect: 'none' }}>

            {team.index + 1}
            &nbsp;
            {team.name}

        </li>
    )
})

export default SortableTeam
