import React from 'react';
import axios from 'axios';
import DateTimePicker from 'react-datetime-picker';
import { TrixEditor } from "react-trix";
import sortObjects from 'sort-objects-array';

export default class Form extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            /**
             * Are we removing the image?
             */
            removeImage: false,
            /**
             * All of the sports in the DB
             */
            sports: [],
            /**
             * All of the teams for the selected sport
             */
            teams: [],
            /**
             * The draft preferences for the selected sport
             */
            draft_preferences: [],
            /**
             * Error message from the server
             */
            error: null,
            /**
             * The default rewards (unedited) from the server for the given sport. This is so if a user changes
             * the reward points on the form itself, it won't change the default values and we can still
             * use those values instead of wiping all inputs to 0 every time there's an update.
             */
            default_rewards: this.props.break.reward_points || [],
            /**
             * The break form values
             */
            form: {
                /**
                 * Name of the break
                 */
                name: this.props.break.name || '',
                /**
                 * Sport for the break
                 */
                sport_id: this.props.break.sport_id || '',
                /**
                 * Reward points JSON
                 * Rewards points is used to hold auction items for auction type breaks 
                 */
                reward_points: this.props.break.reward_points || [
                    {
                        item_title: '',
                        starting_bid_amount: 0,
                        team: '',
                        auction_offset: 0
                    }
                ],
                /**
                 * The cost for the break
                 */
                 cost: this.props.break.cost || '',
                /**
                 * Break image
                 */
                image: this.props.break.image,
                /**
                 * Break description
                 */
                description: this.props.break.description || '',
                /**
                 * The date and time the auction ends
                 */
                auction_end_date_time: this.props.break.auction_end_date_time ? new Date(this.props.break.auction_end_date_time) : new Date,
                /**
                 * The integer percentage for the rewards points given after purchase
                 */
                auction_reward_points: this.props.break.auction_reward_points || '',
                /**
                 * Indicate whether logos for teams will display for users
                 */
                show_logos: this.props.break.show_logos || false,
                /**
                 * The date that payment is due for items of this auction
                 */
                auction_payment_due_date: this.props.break.auction_payment_due_date ? new Date(this.props.break.auction_payment_due_date) : new Date
            }
        }
    }

    componentDidMount() {
        /**
         * Load sports to populate our dropdown
         */
        axios.get('/sports.json').then((response) => {
            this.setState({
                sports: response.data
            });
        });

        /**
         * Whenever we are doing update (when we have a break id), we need to load
         * the same initial data that we would have loaded to get to this point, in order
         * to populate the select boxes and user choices, etc.
         */
        if (this.props.break.id) {
            this.loadSport(this.props.break.sport_id, { ignoreRewards: true });

        }

    }

    /**
     * Handle change on individual select/input
     */
    handleFormUpdate = (e) => {
        // if we have a sport, clear out the existing props and load the sport associations from the server
        if (e.target.name === 'sport_id') {
            this.clearSport();
            this.loadSport(e.target.value);
        }

        let newState = {
            ...this.state,
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        };

        if (e.target.name === "auction_reward_points" || e.target.name === "cost") {
            let sanitizeNum = e.target.value.replace(/[^\d]/g, '');
            sanitizeNum = parseInt(sanitizeNum) ? parseInt(sanitizeNum) : 0;

            if (e.target.name == "auction_reward_points") {
                newState = {
                    ...newState,
                    form: {
                        ...newState.form,
                        auction_reward_points: sanitizeNum
                    }
                };
            } else {
                newState = {
                    ...newState,
                    form: {
                        ...newState.form,
                        cost: sanitizeNum
                    }
                };
            }
        }
        this.setState({ ...newState });
    }

    clearSport = () => {
        this.setState({
            excluded_teams_for_select: null,
            form: {
                ...this.state.form,
                excluded_teams: [],
                draft_preference_id: null
            }
        });
    }

    loadSport = (sportId, options) => {
        const flags = options || {};
        
        this.loadTeams(sportId);
        this.loadDraftPreferences(sportId);

        if (!flags.ignoreRewards) {
            this.loadRewardPoints(sportId);
        }
    }

    loadTeams = (sportId) => {
        axios.get(`/admin/sports/${sportId}/teams.json`).then((response) => {
            this.setState({
                teams: response.data
            });
        });
    }

    loadDraftPreferences = (sportId) => {
        axios.get(`/sports/${sportId}/draft_preferences.json`).then((response) => {
            this.setState({
                draft_preferences: response.data
            });
        });
    }

    loadRewardPoints = (sportId) => {
        if (this.state.form.reward_points.length) {
            return;
        }

        axios.get(`/admin/sports/${sportId}/default_reward_points.json`).then((response) => {
            this.setState({
                form: {
                    ...this.state.form,
                    reward_points: response.data.values
                },
                default_rewards: response.data.values
            });
        });
    }

    /**
     * Build out confirmation fields, joining them by 2 break-lines... this is just an easy helper in case
     * we want to modify individual lines of the confirmation we can break them into methods etc.
     */
    confirmFields = () => {
        const fields = [
            `${this.props.break.id ? 'Update' : 'Create'} Break?`,
            `Name: ${this.state.form.name}`
            // `Draft Preference: ${this.draftPreferenceName()}`
        ].join('\n\n');

        return window.confirm(fields);
    }

    submit = () => {
        // confirm field details before submitting
        if (!this.confirmFields()) {
            return;
        }

        const bodyFormData = new FormData();

        Object.keys(this.state.form).forEach((input) => {
            const value = this.state.form[input];

            // if we don't parse the JSON fields, they will not submit properly
            const parsed = ['excluded_teams', 'reward_points'].includes(input) ? JSON.stringify(value) : value;

            if (!(input === 'image' && parsed === undefined)) {
                bodyFormData.append(`break[${input}]`, parsed);
            }
        });

        bodyFormData.append('authenticity_token', this.props.token);
        bodyFormData.append('remove_image', this.state.removeImage);

        axios({
            method: this.props.break.id ? 'patch' : 'post',
            url: `${this.props.path}.json`,
            data: bodyFormData,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        }).then((response) => {
            if (response.data.success) {
                if (response.data.success_type === "create") {
                    /**
                     * If the auction was sucessfully created then directly go
                     * to the items index page for that auction where items can be created right away
                     */
                    location.assign(`/admin/auctions/${response.data.auction_id}/items`);
                    
                }
                else {
                    // if the success type is not create then it must be update, redirect to the auctions index
                    location.assign("/admin/auctions/");
                }
            } else {
                // when we have an error when saving, just show an error message
                // since we can't re-render using flash here.
                this.setState({ error: response.data.message });
            }
        });
    }

    handleImageChange = (e) => {
        this.setState({
            removeImage: false,
            form: {
                ...this.state.form,
                image: e.target.files[0]
            }
        });
    }

    renderImage = () => {
        if (!this.props.image || this.state.removeImage) {
            return null;
        }

        return (
            <div className='form-group'>
                <a href="#" className='red' onClick={this.removeImage}>Remove image</a>
                <img style={{ display: 'block' }} src={this.props.image} alt="break image" className="img-fluid" />
            </div>
        );
    }

    removeImage = (e) => {
        e.preventDefault();
        this.setState({
            removeImage: true
        });
    }

    handleTrixChange = (html, text) => {
        this.setState({
            form: {
                ...this.state.form,
                description: html
            }
        });
    }

    cancel = () => {
        if (!window.confirm('Are you sure you want to cancel? All data will be lost.')) {
            return;
        }

        window.location.assign('/admin/auctions');
    }

    handleAuctionDate = e => {
        this.setState({
            form: {
                ...this.state.form,
                auction_end_date_time: e
            }
        });
    }

    handleCheckboxUpdate = e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: !this.state.form[e.target.name]
            }
        });
    }

    handlePaymentDueDate = e => {
        this.setState({
            form: {
                ...this.state.form,
                auction_payment_due_date: e
            }
        });
    }

    render() {
        const sortedTeams = sortObjects(this.state.teams, 'name')

        return (
            <div>
                {
                    this.state.error && (
                        <div className="alert alert-danger">{this.state.error}</div>
                    )
                }

                <div className="row">
                    <div className="col">

                        <div className="form-group">
                            <label>Name</label>
                            <input
                                name='name'
                                onChange={this.handleFormUpdate}
                                value={this.state.form.name}
                                type="text"
                                className="form-control" />
                        </div>

                        <div className="form-group">
                            <label>Set Ending Date/Time</label>
                            <DateTimePicker
                                name='auction_end_date_time'
                                value={this.state.form.auction_end_date_time}
                                onChange={this.handleAuctionDate}
                            />
                        </div>

                        <div className="form-group">
                            <label>Set Payment Due Date/Time</label>
                            <DateTimePicker
                                name='auction_payment_due_date'
                                value={this.state.form.auction_payment_due_date}
                                onChange={this.handlePaymentDueDate}
                            />
                        </div>

                        <div className="form-group">
                            <label>Sport</label>
                            <select
                                name='sport_id'
                                onChange={this.handleFormUpdate}
                                value={this.state.form.sport_id}
                                className="form-control">

                                <option value=''></option>

                                {
                                    this.state.sports.map((sport, key) => {
                                        return (
                                            <option key={key} value={sport.id}>{sport.name}</option>
                                        )
                                    })
                                }

                            </select>
                        </div>

                        <div className="form-group">
                            <label>My Cost</label>
                            <input
                                className='form-control'
                                name="cost"
                                type="number"
                                value = {this.state.form.cost || ''}
                                onChange={this.handleFormUpdate}
                            />
                        </div>

                        <div className="form-group">
                            <label>Set Reward points to be given after payment, x% of total paid is rewarded</label>
                            <input
                                className='form-control'
                                name="auction_reward_points"
                                type="text"
                                value = {this.state.form.auction_reward_points || ''}
                                onChange={this.handleFormUpdate}
                            />
                        </div>

                        <div className="form-group">
                            <label>Show Team Logos For Items
                                &nbsp;
                                <input
                                    name="show_logos"
                                    type="checkbox"
                                    value={this.state.form.show_logos}
                                    checked={this.state.form.show_logos}
                                    onChange={this.handleCheckboxUpdate} />
                            </label>
                        </div>

                        <div className="form-group">
                            <label>Description</label>
                            <TrixEditor
                                onChange={this.handleTrixChange}
                                value={this.state.form.description} />
                        </div>

                        <div className="form-group">
                            <label style={{ display: 'block' }}>Image</label>
                            <input
                                name='image'
                                onChange={this.handleImageChange}
                                defaultValue={this.state.form.image}
                                type="file" />
                        </div>

                        {this.renderImage()}

                    </div>

                </div>
                <button className='btn btn-sm btn-success mb-5' onClick={this.submit}>
                    {`${this.props.break.id ? 'Update' : 'Create'} Break`}
                </button>

                <button className='btn btn-sm btn-danger mb-5 ml-2' onClick={this.cancel}>
                    Cancel
                </button>

            </div>

        );
    }
}
