import React from 'react'
import Team from '../../lib/Team';

const PaymentItem = (props) => {

	const paymentDue = () => {
		let dueDate = new Date(props.item.auction_data.auction_payment_due_date);
		return <span> {dueDate.toLocaleDateString()}</span>
	}

	return (
		<>
			<tr className="auction-item">
				<td className="auction-item-logo">
					{
						props.item.image_data.team_data === "no_data" ? 
							<span></span>
							:
							<img src={Team.imagePath(props.item.image_data.team_data, props.item.image_data.sport_data)} alt="team" />
					}
				</td>
				<td className="auction-item-details">
					<div className="auction-item-details-name">{props.item.title}</div>
					<div className="auction-item-details-status badge badge-success">WON</div>
				</td>
				<td className="auction-item-meta"></td>
				<td className="auction-item-meta">
					<span className="auction-item-meta-label">PAYMENT DUE:</span>
					{paymentDue()}
				</td>
				<td className="auction-item-actions">
					YOUR WINNING BID: ${parseFloat(props.item.current_bid).toFixed(2)}
				</td>
			</tr>
		</>
	)
};

export default PaymentItem;