import React from 'react'
import UserCards from './UserCards'
import shuffle from 'shuffle-array'

export default class RandomizingBreak extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            shuffled: 0,
            interval: window.setInterval(this.shuffle, 2000),
            /**
             * @TODO we inject index right now just to see the shuffle happen since during dev it's always the same user -- remove later
             */
            entries: this.props.entries.map((entry, index) => {
                return {
                    ...entry,
                    user: {
                        ...entry.user,
                        index: index
                    }
                }
            })
        }
    }

    shuffle = () => {
        this.setState({
            shuffled: this.state.shuffled + 1,
            entries: shuffle(this.state.entries)
        });

        if (this.state.shuffled === 3) {
            window.clearInterval(this.state.interval);

            // mark the parent as shuffled so it knows to move onto the next component
            this.props.setShuffled();
        }
    }

    render = () => {
        return (
            <div className="draft-col cards card-col">
                <h2 className="mt-0">{this.props.break.name}</h2>
                <div className="card-stack">
                    <div className="spots-remaining text-center digital red-glow mb-4">
                        Initializing Random Draft Order
                    </div>
                    <UserCards users={this.state.entries.map(e => e.user)} user={this.props.user} bk={this.props.break} />
                </div>
            </div>
        )
    }
}