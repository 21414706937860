import React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import axios from 'axios'

const setPending = (e, bk, token) => {
    e.preventDefault();

    axios.post(`/admin/standard_breaks/${bk.id}/pending.json`, {
        authenticity_token: token
    }).then(({ data }) => {
        if (data.error) {
            alert(data.message)
        }
        window.location.reload()
    })
}

const OnSaleBreak = SortableElement(({ iter, bk, token, onSaleLive }) => {
    const saleIsLive = iter === 0 && onSaleLive;

    const pointsPrice = bk.reward_points_redemption_allowed && bk.reward_points_required_per_entry
      ? ` or ${bk.reward_points_required_per_entry} (point${bk.reward_points_required_per_entry === 1 ? '' : 's'})`
      : '';

    return (
        <li className='list-group-item' style={{ userSelect: 'none' }}>
            <div className="row">
                <div className={`name col-${(saleIsLive ? 10 : 12)}`}>
                    <b>{bk.name}</b>
                </div>
                { saleIsLive && (
                    <div className="name col-2 text-right">
                        <span className='on-sale-live'>LIVE</span>
                    </div>
                ) }
                {
                    bk.draft_preference_name && (
                        <div className="draft-preference col-12">
                            Draft Preference: {bk.draft_preference_name}
                        </div>
                    )
                }
                <div className="details col-12 col-md-6">
                    {`Price: \$${parseInt(bk.cost_per_spot, 10)}`}
                    { pointsPrice }
                    <br />
                    {`Sold: ${bk.entries_sold} | Max: ${bk.max_spots}`}
                </div>
                <div className="actions col-12 col-md-6 text-left text-md-right">
                    <a href='#' onClick={(e) => setPending(e, bk, token)}>Pending</a>
                </div>
            </div>
        </li>
    )
})

export default OnSaleBreak
