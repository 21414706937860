import React from 'react'

const Instructions = props => {
    return (
        <div style={{ textAlign: 'center', marginTop: '5em' }}>
            <p>In order to set your desired draft preference, click on the sport you wish to set.</p>
            <p>After selecting the sport, select the draft preference by year you would like to set.</p>
            <p>Then, in the right column, drag the cards to your desired draft order.</p>
        </div>
    )
}

export default Instructions
