import React from 'react'
import UserCards from './UserCards'
import sortObjects from 'sort-objects-array';

const PendingBreak = props => {
    return (
        <div className="draft-col cards card-col">
            <h2 className="mt-0">{props.break.name}</h2>
            <div className="glow-text text-center">
                On Sale
            </div>
            <div className="spots-remaining text-center digital red-glow mb-4">
                {props.break.max_spots - props.break.entries_sold} Spots Remaining
            </div>
            <div className="text-center">
                <a href="/breaks" className="btn btn-lg">Buy Spots</a>
                <div className="card-stack mt-2 pl-0 pr-0">
                    <UserCards
                        users={sortObjects(props.entries, 'id').map(e => e.user)}
                        user={props.user}
                        bk={props.break} />
                </div>
            </div>
        </div>
    )
}

export default PendingBreak
