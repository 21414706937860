import React from 'react'

const BreakDetailsBody = ({ cost, spots, description, excludedTeams, unitOfSale }) => {
    return (
        <div>
            <div className="break-controls">
                <div className="row details text-center">
                    <div className="col-6">
                        <b>${Number.parseFloat(cost).toFixed(2)}</b>
                        <span>Cost per {unitOfSale || 'spot'}</span>
                    </div>
                    
                    <div className="col-6">
                        <b>{spots}</b>
                        <span>Spots Remaining</span>
                    </div>
                </div>
            </div>

            {
                excludedTeams.length ? (
                    <p className="excluded-teams">
                        Teams not available: { excludedTeams.map(t => t.name).join(', ') }
                    </p>
                ) : null
            }

            <div className="details-content" dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
    )
}

export default BreakDetailsBody
