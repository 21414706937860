import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import SortableTeamCard from './SortableTeamCard'

const SortableTeamCardContainer = SortableContainer(({ teams, selectedSport }) => {
    return (
        <div>
            {
                teams.map((team, index) => (
                    <SortableTeamCard
                        key={`item-${team.id}`}
                        index={index}
                        team={{ ...team, index: index }}
                        selectedSport={selectedSport} />
                ))
            }
        </div>
    )
})

export default SortableTeamCardContainer