import React from 'react'
import Team from '../../lib/Team'

const DraftOrderEntry = ({ entry, mine }) => {
    return (
        <div className={`card card-team slim ${mine ? 'card-mine' : ''}`}>
            <div className="card-top">
                {entry.entry &&
                    <div className="sort-num">{entry.entry.draft_order}</div>
                }

                {
                    entry.team && (
                        <div className="logo">
                            <img
                                src={Team.imagePath(entry.team, entry.sport)}
                                alt={entry.team.name} />
                        </div>
                    )
                }
                <div className="label">
                    <span>
                        {entry?.item ? `${entry.item.title} - ` : ""}{entry.username}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default DraftOrderEntry
