import React, { useEffect, useState } from 'react'
import moment from 'moment';

const AuctionCountDown = (props) => {

	const [days, setDays] = useState(null);
	const [hours, setHours] = useState(null);
	const [minutes, setMinutes] = useState(null);
	const [seconds, setSeconds] = useState(null);

	useEffect(() => {
		setTimeout(() => {
			calculateTimeLeft();
		}, 1000);

		if (window.calculateInterval) {
			window.clearInterval(window.calculateInterval)
		}

		if (!props.isCard) {
			if (days <=0 && minutes <= 0 && minutes <= 0 && seconds <= 0) {
				props.hideActions(true);
			} else {
				props.hideActions(false);
			}
		}
		
		window.calculateInterval = window.setInterval(calculateTimeLeft(), 1000)
	}, [days, hours, minutes, seconds]);

	const calculateTimeLeft = () => {
		const now = moment()
		const next = props.auctionItem ? moment(props.auctionItem.offset_date) : now
		const diff = next.diff(now)
		const duration = moment.duration(diff)

		if (duration.days() <= 0 && duration.hours() <= 0 && duration.minutes() <= 0 && duration.seconds() <= 0) {
            // stop the calculation so it stops at 0
            window.clearInterval(window.calculateInterval)
		}

		setDays(duration.days());
		setHours(duration.hours());
		setMinutes(duration.minutes());
		setSeconds(duration.seconds());
	}

	return (
		props.isCard ? 
			<span style={{ fontSize: "1.2rem" }}>ITEM ENDS IN {minutes > 0 ? minutes + "M" : ""} {seconds > 0 ? seconds + "S" : ""}</span>
			:
			<span className="auction-item-meta-detail">
				{days > 0 ? days + "D" : ""} {hours > 0 ? hours + "H" : ""} {minutes > 0 ? minutes + "M" : ""} {seconds > 0 ? seconds + "S" : ""}
			</span>
	);
}

export default AuctionCountDown;