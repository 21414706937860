import React from 'react'
import YearCard from './YearCard'

const SelectYear = ({ draftPreferences, handleDraftPreferenceSelect, selected }) => {
    if (!draftPreferences.length) {
        return null;
    }

    return (
        <div className="col-sm-12 col-md-6 col-lg-4 cards card-col">
            <h2 className="mt-0">SELECT A YEAR</h2>
            {
                draftPreferences.map((dp, key) => {
                    return (
                        <YearCard
                            key={key}
                            draftPreference={dp}
                            handleDraftPreferenceSelect={handleDraftPreferenceSelect}
                            selected={selected} />
                    )
                })
            }
        </div>
    )
}

export default SelectYear
