import React, { useState, useEffect } from 'react'
import AuctionItemsContainer from './AuctionItemsContainer';

const Auction = (props) => {

	const [auction, setAuction] = useState(props.auction);
	const [auctionItems, setAuctionItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [sortOption, setSortOption] = useState("end-time");

	useEffect(() => {
		const sortByEndTime = props.auction_items.sort((a, b) => new Date(a.offset_date) - new Date(b.offset_date));
		setAuctionItems(props.auction_items);
		setFilteredItems(sortByEndTime);
	}, [])

	const handleSearch = (e) => {
		/**
		 * limit the search from invoking unless the search term is at least 3 characters long
		 * so that the browser doesn't get slowed down from trying to filter hundreds of items
		 * for every character search. We also want to un-filter everything if user clears out 
		 * the search term otherwise the filter will stay stuck at whatever it previously filtered
		 */
		if (e.target.value.length >= 3 || e.target.value.length === 0) {
			let inputTerm = e.target.value.toLowerCase();

			const filteredItems = props.auction_items.filter(item => item.title.toLowerCase().includes(inputTerm) || item.image_data.team_data.name?.toLowerCase().includes(inputTerm));
			setFilteredItems(filteredItems);		
		}
	}

	const handleSort = (e) => {
		// by sorting a copy of filteredItems, this will ensure that whatever search filter the user is already using will be preserved while they sort
		let sortedItems;
		const sortValue = e?.target?.value ? e.target.value : sortOption;

		switch (sortValue) {
			case "team":
				sortedItems = props.auction_items.slice().sort((a, b) => {
					let x = a.image_data.team_data?.name ? a.image_data.team_data.name.toLowerCase() : "";
					let y = b.image_data.team_data?.name ? b.image_data.team_data.name.toLowerCase() : "";
					return x.localeCompare(y)
				});
				setSortOption(sortValue)
				setAuctionItems(sortedItems);
				break;
			case "end-time":
				sortedItems = props.auction_items.slice().sort((a, b) => new Date(a.offset_date) - new Date(b.offset_date));
				setSortOption(sortValue)
				setAuctionItems(sortedItems);
				break;
			case "lowest-bid":
				sortedItems = props.auction_items.slice().sort((a, b) => {
					const x = a.current_bid ? parseInt(a.current_bid) : parseInt(a.starting_bid_amount);
					const y = b.current_bid ? parseInt(b.current_bid) : parseInt(b.starting_bid_amount);
					return x - y;
				});
				setSortOption(sortValue)
				setAuctionItems(sortedItems);
				break;
			case "highest-bid":
				sortedItems = props.auction_items.slice().sort((a, b) => {
					const x = a.current_bid ? parseInt(a.current_bid) : parseInt(a.starting_bid_amount);
					const y = b.current_bid ? parseInt(b.current_bid) : parseInt(b.starting_bid_amount);
					return y - x;
				});
				setSortOption(sortValue)
				setAuctionItems(sortedItems);
				break;
			default:
				break;
		}
	}

	const firstItemEndDate = () => {
		const firstItem = auctionItems[0] ? new Date(auctionItems[0].offset_date) : null;
		const firstItemDate = firstItem ? firstItem.toLocaleDateString() : null;
		const firstItemTime = firstItem ? firstItem.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : null;
		
		if (firstItem) {
			return `First item ends on ${firstItemDate} at ${firstItemTime}`;
		}
		else {
			return "There are no items on sale";
		}
	}

	return (
		<div className="page stadium-bg">
			<div className="container-xl">
				<div className="row justify-content-center mb-4">
					<div className="col-xl-9 col-lg-10">
						<h1 className="page-header">{auction.name}</h1>
						{/* <p className="intro">{auction.description}</p> */}
						<div className="intro" dangerouslySetInnerHTML={{__html: auction.description}}></div>
						<div className="row">
							<div className="col">
								<div className="auction-digital">
									<div className="auction-digital-content">{firstItemEndDate()}</div>
								</div>
							</div>
						</div>
						<div className="row auction-filters dark-form">
							<div className="col-sm-8 pb-3">
								<div className="search-field">
									<input className="search form-control" placeholder="Search w/ Any : First Name, Last Name, Team, QB, RC, or RPA" type="text" onChange={handleSearch}/>
									<i className="far fa-search"></i>
								</div>
							</div>
							<div className="col-sm-4">
								<select className="custom-select" onChange={handleSort}>
									<option disabled selected value="">Sort option</option>
									<option value="team">Sort By Teams</option>
									<option value="end-time">Sort By Ending Time</option>
									<option value="lowest-bid">Sort By Lowest Bid</option>
									<option value="highest-bid">Sort By Highest Bid</option>
									{/* <option value="none">No Sort</option> */}
								</select>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<table className="auction-items">
									<tbody>
										<AuctionItemsContainer
											auction={auction}
											auctionItems={auctionItems}
											filteredItems={filteredItems}
											token={props.token}
											user={props.user}
											userSignedIn={props.user_signed_in}
											bids={props.bids}
											itemReminders={props.item_reminders}
											handleSort={handleSort} />
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Auction
