import React from 'react'
import Team from '../../lib/Team';

const FlippingTeamCard = ({ entry, user, sport, draft_type, complete }) => {
    return (
        <div className="row">
            <div className={`card-flip col ${entry.flipped || entry.complete ? 'flip' : null}`}>
                <div className={`card card-no-team card-drag card-front ${entry.user.id === user ? 'card-mine' : null}`}>
                    <div className="card-top">
                        <div className="card-content">
                            <div className="label"><span>{entry.user.username}</span></div>
                        </div>
                    </div>
                </div>
                <div className={`card ${draft_type === 'random_hit' ? 'card-no-team' : 'card-team'} card-drag card-back ${entry.user.id === user ? 'card-mine' : null}`}>
                    <div className="card-top">
                        <div className="position">{entry.entry.draft_order}</div>
                        {
                            draft_type === 'random_hit' ? null : (
                                <div className="logo"><img src={Team.imagePath(entry.team, sport)} alt="team" /></div>
                            )
                        }
                        <div className="card-content">
                            <div className="label"><span>{entry.user.username}</span></div>
                            {
                                draft_type === 'random_hit' ? null : (
                                    <div className="team">{entry.team.name}</div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlippingTeamCard
