import React, { useEffect, useState } from 'react'

import Team from '../../lib/Team';
import Sport from '../../lib/Sport';

const AuctionItemLogo = (props) => {
  
  const showLineItemLogo = (teamData, sportData, auctionSport, showLogos) => {
		if(showLogos) {
			if (teamData !== "no_data") {
				return <img src={Team.imagePath(teamData, sportData)} alt="team" />
			} else {
				// Jank logic for the weird dimensions of the NBA logo
				// We can clean this up later if we need to
				if (auctionSport === 3) {
					return <img src={Sport.imagePath(auctionSport)} alt="team" className="nba-logo-dimensions" />
				} else {
					return <img src={Sport.imagePath(auctionSport)} alt="team"/>
				}
			}
		} else {
			return <span></span>
		}
	}

  const showAuctionModalLogo = (teamData, sportData, auctionSport) => {
    if (teamData !== "no_data") {
      return <img src={Team.imagePath(teamData, sportData)} alt="team" />
    } else {
      // Jank logic for the weird dimensions of the NBA logo
      // We can clean this up later if we need to
      return <img src={Sport.imagePath(auctionSport)} alt="team" />
    }
	}

  const evalLineItemOrModalImage = () => {
    if (props.auctionModal) {
      if (props.auctionItem && props.auctionItem.image_data) {
        return showAuctionModalLogo(props.auctionItem.image_data.team_data, props.auctionItem.image_data.sport_data, props.auction.sport_id)
      }
    } else {
      if (props.auctionItem && props.auctionItem.image_data) {
        return showLineItemLogo(props.auctionItem.image_data.team_data, props.auctionItem.image_data.sport_data, props.auction.sport_id, props.auction.show_logos)
      }
    }
  }

	return (
    <>
      {evalLineItemOrModalImage()}
    </>
  )
}

export default AuctionItemLogo;