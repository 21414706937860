import React from 'react'
import pluralize from 'pluralize'
import axios from 'axios'

export default class BreakRedeem extends React.Component {

    /**
     * The amount of entries the user can buy with their reward points
     */
    redeemableQuantity = () => {
        // the total amount of entries the user could buy with their reward points, according to the cost of this break
        const totalAvailable = Math.floor(this.props.rewardPoints / this.props.cost);
        // if the total amount of entries the user could redeem is greater than the available spots, show the available spots
        return totalAvailable > this.props.spotsAvailable ? this.props.spotsAvailable : totalAvailable;
    }

    redeem = () => {
        if (this.props.quantity > this.redeemableQuantity()) {
            alert(`You do not have enough reward points. You can redeem up to ${this.redeemableQuantity()} ${pluralize('entry', this.redeemableQuantity())}.`);
            return;
        }

        // if (!window.confirm(`Are you sure you want to redeem ${Number.parseFloat(this.props.cost * this.props.quantity).toLocaleString()} ${pluralize('point', this.props.cost)} for this break? This action cannot be undone.`)) {
        //     return;
        // }

        // console.log(parseInt(this.props.breakId) * 43113)
        // return

        window.location.assign(`/breaks/${parseInt(this.props.breakId) * 43113}/orders/0/confirm_transaction?q=${this.props.quantity}&p=${this.props.cost * this.props.quantity}&r=1`);

        // before we submit an order we need to make sure there are still spots available for this break, in case it is selling out.
        // axios.get(`/breaks/${this.props.breakId}/check_spots/${this.props.quantity}`).then(response => {
        //     if (!response.data.available) {
        //         alert('Sorry, but all spots have been sold out.');
        //         return;
        //     }

        //     // place order
        //     axios.post(`/breaks/${this.props.breakId}/orders`, {
        //         authenticity_token: this.props.token,
        //         order: {
        //             quantity: this.props.quantity,
        //             price_per_item: this.props.cost,
        //             price_total: this.props.quantity * this.props.cost,
        //             is_reward_points_redemption: true
        //         }
        //     }).then(order => {
        //         if (order.data.success) {
        //             window.location.assign(`/breaks/${order.data.order.break_id}/orders/${order.data.order.id}`);
        //         } else {
        //             alert(order.data.message);
        //         }
        //     });

        // })
    }

    render = () => {
        return (
            <button
                onClick={this.redeem}
                className="btn btn-primary ml-0 mr-0"
                style={{ width: '100%', height: '50px', padding: 0 }}
                disabled={!this.props.isEnabled}
            >
                Redeem with Points
                -
                Balance: {Number.parseFloat(this.props.rewardPoints).toLocaleString()},
                Cost per spot: {Number.parseInt(this.props.cost).toLocaleString()},
                Max: { this.redeemableQuantity()}
            </button>
        )
    }
}
