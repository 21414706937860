import React from 'react';
import Select from 'react-select';

export default function SearchableSelect(props) {
  const defaultValue = props.selected
    ? [ { label: props.selected, value: props.selected } ]
    : null;

  return (
    <Select
      name={props.name}
      closeMenuOnSelect={true}
      placeholder='Please choose...'
      options={props.options}
      defaultValue={defaultValue}
    />
  )
}
