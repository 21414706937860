import React from 'react';

export default function AuctionItems(props) {
    
    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <td>Item title</td>
                        <td>Starting Bid $ Amount</td>
                        <td>Team</td>
                        <td>Items Minute Offset</td>
                        <td>
                            <button className="form-control" onClick={props.handleAddAuctionRow}>+</button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.auctionItems.map((auctionItem, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <input
                                            name="item_title"
                                            className="form-control"
                                            type="text"
                                            value={auctionItem.item_title}
                                            onChange={(e) => props.handleAuctionItems(e, index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            name="starting_bid_amount"
                                            className="form-control"
                                            type="number"
                                            min="0"
                                            value={auctionItem.starting_bid_amount}
                                            onChange={(e) => props.handleAuctionItems(e, index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            name="team"
                                            className="form-control"
                                            type="text"
                                            value={auctionItem.team}
                                            onChange={(e) => props.handleAuctionItems(e, index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            name="auction_offset"
                                            className="form-control"
                                            type="number"
                                            min="0"
                                            step="1"
                                            value={auctionItem.auction_offset}
                                            onChange={(e) => props.handleAuctionItems(e, index)}
                                        />
                                    </td>
                                    <td>
                                        <button className="form-control" onClick={() => props.handleRemoveAuctionRow(index)}>-</button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
};
