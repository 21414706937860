import React from 'react';
import axios from 'axios';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';
import Inputs from '../default_reward_points/Inputs';
import { TrixEditor } from "react-trix";
import sortObjects from 'sort-objects-array';

export default class Form extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            /**
             * The total amount of revenue if all the spots are sold.
             */
            revenueIfSold: null,
            /**
             * Are we removing the image?
             */
            removeImage: false,
            /**
             * All of the sports in the DB
             */
            sports: [],
            /**
             * All of the teams for the selected sport
             */
            teams: [],
            /**
             * The draft preferences for the selected sport
             */
            draft_preferences: [],
            /**
             * Error message from the server
             */
            error: null,
            /**
             * The selected excluded teams snapshot of state... just an easier way to track and manage multi selects
             */
            excluded_teams_for_select: this.props.excluded_teams,
            /**
             * The default rewards (unedited) from the server for the given sport. This is so if a user changes
             * the reward points on the form itself, it won't change the default values and we can still
             * use those values instead of wiping all inputs to 0 every time there's an update.
             */
            default_rewards: this.props.break.reward_points || [],
            /**
             * The break form values
             */
            form: {
                /**
                 * Name of the break
                 */
                name: this.props.break.name || '',
                /**
                 * Cost per shot
                 */
                cost_per_spot: this.props.break.cost_per_spot || '',
                /**
                 * Sport for the break
                 */
                sport_id: this.props.break.sport_id || '',
                /**
                 * Draft type
                 */
                draft_type: this.props.break.draft_type || '',
                /**
                 * Max spots
                 */
                max_spots: this.props.break.max_spots || '',
                /**
                 * Excluded teams
                 */
                excluded_teams: this.props.break.excluded_teams || [],
                /**
                 * Break's draft preference
                 */
                draft_preference_id: this.props.break.draft_preference_id || '',
                /**
                 * Reward points JSON
                 */
                reward_points: this.props.break.reward_points || [],
                /**
                 * Break image
                 */
                image: this.props.break.image,
                /**
                 * Break description
                 */
                description: this.props.break.description || '',
                /**
                 * Are reward point redemptions allowed on this break?
                 */
                reward_points_redemption_allowed: this.props.break.reward_points_redemption_allowed || false,
                /**
                 * The number of required points required per entry
                 */
                reward_points_required_per_entry: this.props.break.reward_points_required_per_entry || ''
            }
        };
    }

    componentDidMount() {
        /**
         * Load sports to populate our dropdown
         */
        axios.get('/sports.json').then((response) => {
            this.setState({
                sports: response.data
            });
        });

        /**
         * Whenever we are doing update (when we have a break id), we need to load
         * the same initial data that we would have loaded to get to this point, in order
         * to populate the select boxes and user choices, etc.
         */
        if (this.props.break.id) {
            this.loadSport(this.props.break.sport_id, { ignoreRewards: true });

            /**
             * When we set the revenue here, the backend has already set the max spots
             * based on excluded teams and max spots, depending on the draft type.
             * That being the case, we can just multiply here instead of what we do when
             * updates are made in the UI.
             */
            this.setState({ revenueIfSold: this.props.break.cost_per_spot * this.props.break.max_spots });
        }
    }

    /**
     * Handle change on individual select/input
     */
    handleFormUpdate = (e) => {
        // if we have a sport, clear out the existing props and load the sport associations from the server
        if (e.target.name === 'sport_id') {
            this.clearSport();
            this.loadSport(e.target.value);
        }

        let newState = {
            ...this.state,
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        };

        /**
         * Here we build out the new version of our revenue data, really anything that can impact
         * revenue calculations. We build that object and update the individual property that
         * e.target.name represents.
         */
        if (['cost_per_spot', 'max_spots', 'draft_type'].includes(e.target.name)) {
            const revenue = {
                cost_per_spot: this.state.form.cost_per_spot,
                max_spots: this.state.form.max_spots,
                draft_type: this.state.form.draft_type,
                [e.target.name]: e.target.value
            };

            const spotsTotal = revenue.draft_type === 'team_based' ? (this.state.teams.length - this.state.form.excluded_teams.length) : revenue.max_spots;

            const total = revenue.cost_per_spot * spotsTotal;

            newState = {
                ...newState,
                revenueIfSold: total,
                form: {
                    ...newState.form,
                    reward_points: this.generateNewRewards(spotsTotal)
                }
            };
        }

        /**
         * Whenever the user goes from team based draft type to limited quantity
         * we need to clear out the draft preference id that the user had selected
         * since limited quantity breaks don't have draft preferences.
         */
        if (e.target.name === 'draft_type' && e.target.value === 'random_hit') {
            newState = {
                ...newState,
                form: {
                    ...newState.form,
                    draft_preference_id: ''
                }
            };
        }

        this.setState({ ...newState });
    }

    generateNewRewards = (spotsTotal) => {
        let newRewards = [];

        // loop over the new max spots, and that's how much our final set or whatever should be...
        for (let i = 0; i < spotsTotal; ++i) {
            const spotNumber = i + 1;

            // find the spot value from the default set...
            const percentageFromDefault = this.state.default_rewards.filter(r => r.spot === spotNumber)[0] || { percentage: null }

            newRewards.push({
                spot: spotNumber,
                percentage: percentageFromDefault.percentage
            });
        }

        return newRewards;
    }

    clearSport = () => {
        this.setState({
            excluded_teams_for_select: null,
            form: {
                ...this.state.form,
                excluded_teams: [],
                draft_preference_id: null
            }
        });
    }

    loadSport = (sportId, options) => {
        const flags = options || {};
        
        this.loadTeams(sportId);
        this.loadDraftPreferences(sportId);

        if (!flags.ignoreRewards) {
            this.loadRewardPoints(sportId);
        }
    }

    loadTeams = (sportId) => {
        axios.get(`/admin/sports/${sportId}/teams.json`).then((response) => {
            this.setState({
                teams: response.data
            });
        });
    }

    loadDraftPreferences = (sportId) => {
        axios.get(`/sports/${sportId}/draft_preferences.json`).then((response) => {
            this.setState({
                draft_preferences: response.data
            });
        });
    }

    loadRewardPoints = (sportId) => {
        if (this.state.form.reward_points.length) {
            return;
        }

        axios.get(`/admin/sports/${sportId}/default_reward_points.json`).then((response) => {
            this.setState({
                form: {
                    ...this.state.form,
                    reward_points: response.data.values
                },
                default_rewards: response.data.values
            });
        });
    }

    teamForSelect = (team) => {
        return {
            value: team.id,
            label: team.name
        };
    }

    handleExcludedTeamsSelect = (teams) => {
        const targetTeams = teams || [];

        const spotsTotal = this.state.teams.length - targetTeams.length;

        this.setState({
            form: {
                ...this.state.form,
                excluded_teams: (targetTeams).map(t => t.value),
                reward_points: this.generateNewRewards(spotsTotal)
            },
            excluded_teams_for_select: teams,
            revenueIfSold: this.state.form.cost_per_spot * spotsTotal
        });
    }

    /**
     * Get the selected draft preference name if there is one, otherwise just show '' instead of undefined
     */
    draftPreferenceName = () => {
        return (this.state.draft_preferences.filter(dp => {
            return dp.draft_preference.id === Number.parseInt(this.state.form.draft_preference_id);
        })[0] || { draft_preference: { name: '' } }).draft_preference.name;
    }

    /**
     * Build out confirmation fields, joining them by 2 break-lines... this is just an easy helper in case
     * we want to modify individual lines of the confirmation we can break them into methods etc.
     */
    confirmFields = () => {
        const fields = [
            `${this.props.break.id ? 'Update' : 'Create'} Break?`,
            `Name: ${this.state.form.name}`,
            `Draft Preference: ${this.draftPreferenceName()}`
        ].join('\n\n');

        return window.confirm(fields);
    }

    submit = () => {
        // confirm field details before submitting
        if (!this.confirmFields()) {
            return;
        }

        const method = this.props.break.id ? axios.patch : axios.post;

        const bodyFormData = new FormData();

        Object.keys(this.state.form).forEach((input) => {
            const value = this.state.form[input];

            // if we don't parse the JSON fields, they will not submit properly
            const parsed = ['excluded_teams', 'reward_points'].includes(input) ? JSON.stringify(value) : value;

            if (!(input === 'image' && parsed === undefined)) {
                bodyFormData.append(`break[${input}]`, parsed);
            }
        });

        bodyFormData.append('authenticity_token', this.props.token);
        bodyFormData.append('remove_image', this.state.removeImage);

        axios({
            method: this.props.break.id ? 'patch' : 'post',
            url: `${this.props.path}.json`,
            data: bodyFormData,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        }).then((response) => {
            if (response.data.success) {
                // whenever we have a successful save on the server, just load the index
                // since we will show a flash message there, keeping similar workflow
                // as slim views.
                location.assign('/admin/standard_breaks');
            } else {
                // when we have an error when saving, just show an error message
                // since we can't re-render using flash here.
                this.setState({ error: response.data.message });
            }
        });
    }

    percentageChange = (values) => {
        this.setState({
            form: {
                ...this.state.form,
                reward_points: values
            }
        });
    }

    handleImageChange = (e) => {
        this.setState({
            removeImage: false,
            form: {
                ...this.state.form,
                image: e.target.files[0]
            }
        });
    }

    renderImage = () => {
        if (!this.props.image || this.state.removeImage) {
            return null;
        }

        return (
            <div className='form-group'>
                <a href="#" className='red' onClick={this.removeImage}>Remove image</a>
                <img style={{ display: 'block' }} src={this.props.image} alt="break image" className="img-fluid" />
            </div>
        );
    }

    removeImage = (e) => {
        e.preventDefault();
        this.setState({
            removeImage: true
        });
    }

    handleTrixChange = (html, text) => {
        this.setState({
            form: {
                ...this.state.form,
                description: html
            }
        });
    }

    cancel = () => {
        if (!window.confirm('Are you sure you want to cancel? All data will be lost.')) {
            return;
        }

        window.location.assign('/admin/standard_breaks');
    }

    handleCheckboxUpdate = e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: !this.state.form[e.target.name]
            }
        });
    }

    render() {
        const sortedTeams = sortObjects(this.state.teams, 'name')

        return (
            <div>
                {
                    this.state.error && (
                        <div className="alert alert-danger">{this.state.error}</div>
                    )
                }

                <p>Revenue if all spots sold: {this.state.revenueIfSold ? `$${Number.parseFloat(this.state.revenueIfSold).toFixed(2)}` : 'N/A'}</p>

                <div className="row">
                    <div className="col-8">

                        <div className="form-group">
                            <label>Name</label>
                            <input
                                name='name'
                                onChange={this.handleFormUpdate}
                                value={this.state.form.name}
                                type="text"
                                className="form-control" />
                        </div>

                        <div className="form-group">
                            <label>Cost Per Spot</label>
                            <input
                                name='cost_per_spot'
                                onChange={this.handleFormUpdate}
                                value={this.state.form.cost_per_spot}
                                type="number"
                                min='0'
                                step='.1'
                                className="form-control" />
                        </div>

                        <div className="form-group">
                            <label>Sport</label>
                            <select
                                name='sport_id'
                                onChange={this.handleFormUpdate}
                                value={this.state.form.sport_id}
                                className="form-control">

                                <option value=''></option>

                                {
                                    this.state.sports.map((sport, key) => {
                                        return (
                                            <option key={key} value={sport.id}>{sport.name}</option>
                                        )
                                    })
                                }

                            </select>
                        </div>

                        <div className="form-group">
                            <label>Draft Type</label>
                            <select
                                name='draft_type'
                                onChange={this.handleFormUpdate}
                                value={this.state.form.draft_type}
                                className="form-control">

                                <option value=''></option>

                                {
                                    this.props.draft_types.map((draft_type, key) => {
                                        return (
                                            <option key={key} value={draft_type.value}>{draft_type.name}</option>
                                        )
                                    })
                                }

                            </select>
                        </div>

                        {
                            this.state.form.draft_type === 'random_hit' && (

                                <div className="form-group">
                                    <label>Max Spots</label>
                                    <input
                                        name='max_spots'
                                        onChange={this.handleFormUpdate}
                                        value={this.state.form.max_spots}
                                        type="number"
                                        min='1'
                                        step='1'
                                        className="form-control" />
                                </div>
                            )
                        }

                        {
                            this.state.form.draft_type === 'team_based' && (

                                <div className="form-group">
                                    <label>Excluded Teams</label>
                                    <Select
                                        name='excluded_teams'
                                        isMulti
                                        value={this.state.excluded_teams_for_select}
                                        options={sortedTeams.map(t => this.teamForSelect(t))}
                                        onChange={this.handleExcludedTeamsSelect} />
                                </div>
                            )
                        }

                        {
                            this.state.form.draft_type === 'random_hit' ? null : (
                                <div className="form-group">
                                    <label>Draft Preference</label>
                                    <select
                                        name='draft_preference_id'
                                        onChange={this.handleFormUpdate}
                                        value={this.state.form.draft_preference_id}
                                        className="form-control">

                                        <option value=''></option>

                                        {
                                            this.state.draft_preferences.map((dp, key) => {
                                                return (
                                                    <option key={key} value={dp.draft_preference.id}>{dp.draft_preference.name}</option>
                                                )
                                            })
                                        }

                                    </select>
                                </div>
                            )
                        }

                        <div className="form-group">
                            <label>
                                Reward Points Redemption Allowed
                                &nbsp;
                                <input
                                    type='checkbox'
                                    name='reward_points_redemption_allowed'
                                    value={this.state.form.reward_points_redemption_allowed}
                                    checked={this.state.form.reward_points_redemption_allowed}
                                    onChange={this.handleCheckboxUpdate} />
                            </label>
                        </div>

                        {
                            this.state.form.reward_points_redemption_allowed && (
                                <div className="form-group">
                                    <label>Reward Points Required for Entry</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        name='reward_points_required_per_entry'
                                        value={this.state.form.reward_points_required_per_entry}
                                        onChange={this.handleFormUpdate} />
                                </div>
                            )
                        }

                        <div className="form-group">
                            <label>Description</label>
                            <TrixEditor
                                onChange={this.handleTrixChange}
                                value={this.state.form.description} />
                        </div>

                        <div className="form-group">
                            <label style={{ display: 'block' }}>Image</label>
                            <input
                                name='image'
                                onChange={this.handleImageChange}
                                defaultValue={this.state.form.image}
                                type="file" />
                        </div>

                        {this.renderImage()}

                    </div>

                    <div className="col-4">

                        <Inputs
                            onChange={this.percentageChange}
                            values={this.state.form.reward_points}
                            showTotal={true}
                            revenue={this.state.revenueIfSold} />

                    </div>

                </div>
                <button className='btn btn-success mb-5' onClick={this.submit}>
                    {`${this.props.break.id ? 'Update' : 'Create'} Break`}
                </button>

                <button className='btn btn-danger mb-5 ml-2' onClick={this.cancel}>
                    Cancel
                </button>

            </div>

        );
    }
}
