import React, { useEffect, useState } from 'react'
import Cable from '../../lib/Cable'
import axios from 'axios'
import AuctionCard from './AuctionCard'

const AuctionsContainer = (props) => {

    const [auctions, setAuctions] = useState(props.auctions)

	useEffect(() => {
		// axios.get('/auctions.json').then(response => {
		// 	setAuctions(response.data)
		// });
		
		Cable.connect('AuctionsChannel', {
			received: data => {
				console.log('Updated auctions data: ', data);
				setAuctions(data.data)
			}
		});
    }, []); // Empty array needs to be passed as dependency argument to tell useEffect to only run once on mount or else we get an infinite loop

	const showAuctionStatus = () => {
		if (!auctions.length) {
			if (props.announcement.next_auction_break_start_date) {
				const today = new Date();
				const nextAuctionBreakStartDate = new Date(props.announcement.next_auction_break_start_date);
				
				if (nextAuctionBreakStartDate >= today) {
					const auctionDate = nextAuctionBreakStartDate.toLocaleDateString();
					const auctionTime = nextAuctionBreakStartDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
					return <h1 style={{ width: '100%', textAlign: 'center' }}>Next auction starts {auctionDate} {auctionTime} EST</h1>
				}
				else {
					return <h1 style={{ width: '100%', textAlign: 'center' }}>All spots are sold out right now, check back soon.</h1>
				}
			}
			else {
				return <h1 style={{ width: '100%', textAlign: 'center' }}>All spots are sold out right now, check back soon.</h1>
			}
		}
		else {
			return null;
		}
	}

		return (
			<div className="page stadium-bg">
				<div className="container-xl">
					<div className="row justify-content-center mb-4">
						<div className="col-md-8">
							<h1 className="page-header">Current Auctions</h1>
							<p className="intro"></p>
						</div>
					</div>
					<div className="break-container">
						{showAuctionStatus()}

						{
							auctions.map((auction, index) => {
								return <AuctionCard
									key={index}
									auction={auction}
									items={props.items} />
							})
						}
					</div>
				</div>
			</div>
		)
}

export default AuctionsContainer