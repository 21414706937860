import React from 'react'
import MyAuctionItem from './MyAuctionItem';

const MyAuctions = (props) => {
	console.log(props)
	return (
		<div className="page stadium-bg">
			<div className="container-xl">
				<div className="row justify-content-center mb-4">
					<div className="col-xl-9 col-lg-10">
						<h1 className="page-header">MY AUCTIONS</h1>
						<div className="row">
							<div className="col">
								<table className="auction-items">
									<tbody>
										{
											!props.auction_items.length && (
												<h4 style={{ width: '100%', textAlign: 'center' }}><a href="/faqs" style={{color: '#FFF', textDecoration: 'underline'}}>Find out more about auctions</a> or <a href="/auctions" style={{color: '#FFF', textDecoration: 'underline'}}>Go on and git to biddin</a>!</h4>
											)
										}
										
										{
											props.auction_items.map((auctionItem, index) => {
												return (
													<MyAuctionItem
														key={index}
														auctions={props.auctions}
														auctionItem={auctionItem}
														token={props.token}
														user={props.user}
														userSignedIn={props.user_signed_in}
														bids={props.bids}
														paymentPath={props.payment_path}
														itemReminders={props.item_reminders}
													/>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

export default MyAuctions;