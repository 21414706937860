import React from 'react';
import axios from 'axios';
import arrayMove from 'array-move';
import SportTabs from './SportTabs';
import SelectYear from './SelectYear';
import Teams from './Teams';
import Header from './Header';
import Instructions from './Instructions';

/**
 * Parent component for the user draft preference page
 */
export default class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            /**
             * All sports in the system
             */
            sports: [],
            /**
             * The sport the user selected
             */
            selectedSport: null,
            /**
             * The draft preferences for the user's selected sport
             */
            draftPreferences: [],
            /**
             * The draft preference the user selected
             */
            selectedDraftPreference: null,
            /**
             * The user's draft preference for the selected dp
             */
            userDraftPreference: null,
        };
    }

    /**
     * When the component mounts the only thing we load is the sports options for the nav tabs
     */
    componentDidMount() {
        axios.get('/sports.json').then(({ data }) => {
            this.setState({ sports: data });

            // the client wants football to be pre-selected... loop over sports and fire select handler
            // for the football object in our sports response
            this.selectFootball(data);
        });
    }

    /**
     * Loop through response data of sports and pre select football on the sports tabs list
     */
    selectFootball = sports => {
        sports.forEach(sport => {
            try {
                if (sport.name.toLowerCase() === 'football') {
                    this.handleSportSelect(sport.id);
                }
            } catch (e) {
                console.error('Not able to pre-select football: ', e);
            }
        })
    }

    /**
     * Set the selected sport and load that sport's system draft preferences
     */
    handleSportSelect = (sportId) => {
        this.setState({
            selectedSport: sportId,
            selectedDraftPreference: null,
            userDraftPreference: null
        });

        axios.get(`/sports/${sportId}/draft_preferences.json`).then((response) => {
            this.setState({
                draftPreferences: response.data.map(dp => {
                    return {
                        ...dp.draft_preference,
                        userDraftPreferencePresent: dp.user_draft_preference_present
                    }
                })
            });
        });
    }

    /**
     * When a draft preference is selected, we load the user draft preference from the server (if it exists), otherwise
     * we will get back this same draft preference. That way we don't have to do multiple checks etc in the UI, the API
     * gives us back whatever draft preference we are to use, we don't care whether it's a user or system preference.
     */
    handleDraftPreferenceSelect = (dp) => {
        this.setState({ selectedDraftPreference: dp });

        axios.get(`/sports/${this.state.selectedSport}/draft_preferences/${dp.id}/user_draft_preferences.json`).then((response) => {
            this.setState({
                userDraftPreference: response.data
            });
        });
    }

    /**
     * Handle what happens when a user sorts a team. We update the UI instantly, and then dispatch the order to the API, otherwise it would stick
     * when dropping it.
     */
    handleSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) {
            console.log('Draft preference not changed... skipping save');
            return;
        }

        // grab the team id that was just sorted to a new position. since we can move other teams, we can't go off it's new index
        // we have to go off of the team id so we can find it no matter where it ends up
        const sortedTeamId = this.state.userDraftPreference.order[oldIndex].id;

        this.setState({
            userDraftPreference: {
                ...this.state.userDraftPreference,
                order: arrayMove(this.state.userDraftPreference.order, oldIndex, newIndex).map(team => {
                    /**
                     * When the team card is dropped, we inject a 'saving' attribute into the team object itself
                     * so that where it gets rendered in SortableTeamCard we can add a spinner based on whether or not
                     * the server response has come back or not.
                     */
                    if (team.id === sortedTeamId) {
                        return {
                            ...team,
                            saving: true,
                            saved: false
                        };
                    } else {
                        return team;
                    }
                })
            },
            draftPreferences: this.state.draftPreferences.map(dp => {
                /**
                 * Whenever the user sorts a card, we also mark the draft preference as having a user draft preference
                 * so the indicators that tell them they haven't set that preference go away. If they haven't created
                 * a preference yet, the draft preference id and the user draft preference id are the same since a user
                 * DP doesn't exist yet... if the udp exists we don't need to do anything, just return the dp
                 *
                 * @TODO if we replace anything with API calls and don't need to manually set this flag as true, we can
                 * delete this entire draftPreferences map here
                 */
                if (!dp.userDraftPreferencePresent && dp.id === this.state.userDraftPreference.id) {
                    return {...dp, userDraftPreferencePresent: true};
                } else {
                    return dp;
                }
            })
        });

        axios.post(`/sports/${this.state.selectedSport}/draft_preferences/${this.state.selectedDraftPreference.id}/user_draft_preferences.json`, {
            order: this.state.userDraftPreference.order.map(t => t.id),
            authenticity_token: this.props.token
        }).then((response) => {
            /**
             * @todo we might want to think about returning the dp from the API here, showing an alert, and resetting the user's order back...
             */
            if (!response.data.success) {
                alert(response.data.message);
                return;
            }

            this.toggleSaved(sortedTeamId, true);

            // set the team back to not saved after 3 seconds to remove the checkmark
            window.setTimeout(() => this.toggleSaved(sortedTeamId, false), 3000);
        });
    }

    toggleSaved = (teamId, value) => {
        this.setState({
            userDraftPreference: {
                ...this.state.userDraftPreference,
                order: this.state.userDraftPreference.order.map(team => {
                    /**
                     * When the server response comes back, we have to set the state with the dropped
                     * team no longer marked as saving, rather as 'saved' which will toggle the checkmark
                     */
                    if (team.id === teamId) {
                        return {
                            ...team,
                            saving: false,
                            saved: value
                        };
                    } else {
                        return team;
                    }
                })
            }
        });
    }

    /**
     * Return the sport object with the same id as selectedSport in state... just a helper method
     */
    selectedSport = () => {
        return this.state.sports.filter(s => s.id === this.state.selectedSport)[0]
    }

    render() {
        return (
            <div className="page stadium-bg" id="">
                <div className="container-xl">

                    <Header />

                    <SportTabs
                        sports={this.state.sports}
                        selectedSport={this.state.selectedSport}
                        handleSportSelect={this.handleSportSelect} />

                    { !this.state.selectedSport ? <Instructions /> : null }

                    <div className="row justify-content-center">

                        <SelectYear
                            draftPreferences={this.state.draftPreferences}
                            handleDraftPreferenceSelect={this.handleDraftPreferenceSelect}
                            selected={this.state.selectedDraftPreference && this.state.selectedDraftPreference.id} />

                        <Teams
                            handleSortEnd={this.handleSortEnd}
                            teams={this.state.userDraftPreference && this.state.userDraftPreference.order}
                            selectedSport={this.selectedSport()}
                            selectedDraftPreference={this.state.selectedDraftPreference && this.state.selectedDraftPreference.name} />

                    </div>
                </div>
            </div>
        );
    }
}
