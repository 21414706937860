import React from 'react'
import PersonalBreakPaypal from '../PersonalBreakPaypal';
import MoonLoader from 'react-spinners/MoonLoader';

export default class PersonalBreak extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            quantity: 1,
            loading: false
        };
    }

    updateQuantity = (e) => {
        this.setState({ quantity: e.target.value });
    }

    setLoading = loading => {
        this.setState({ loading: loading });
    }

    renderPaypalInputs = () => {
        return (
            <div style={{ display: this.state.loading ? 'none' : 'inline' }}>
                <label>Quantity:</label>
                &nbsp;
                <input
                    onChange={this.updateQuantity}
                    value={this.state.quantity}
                    type="number"
                    step="1"
                    min="1"
                    max={this.props.break.quantity - this.props.break.entries_sold}
                    placeholder="Enter desired quantity..." />

                <PersonalBreakPaypal
                    setLoading={this.setLoading}
                    break={this.props.break}
                    token={this.props.token}
                    getQuantity={() => this.state.quantity} />
            </div>
        );
    }

    renderGuestLoginLink = () => {
        return (
            <a href="/users/sign_in">Sign In to Purchase a Spot</a>
        );
    }

    loaderStyles = () => {
        return [
            'margin: auto',
            'margin-top: 20px',
            'margin-bottom: 20px'
        ].join('; ');
    }

    renderLoader = () => {
        // if (this.state.loading) {
        //     return (
        //         <div className='text-center'>
        //             <MoonLoader
        //                 color='white'
        //                 css={this.loaderStyles()} />
        //             <p>Processing Payment...</p>
        //         </div>
        //     );
        // } else {
        //     return (
        //         <div>
        //             <p className="heading-supplement mb-3">Sale feature or supplementary content</p>
        //             <p className="text-center">Cost per spot: ${Number.parseFloat(this.props.break.price).toFixed(2)}</p>
        //             <p className="text-center">Spots Remaining: {this.props.break.quantity - this.props.break.entries_sold}</p>
        //             <div className="text-center mb-4">Break Image</div>
        //         </div>
        //     )
        // }


        if (this.state.loading) {
            return (
                <div className='text-center'>
                    <MoonLoader
                        color='white'
                        css={this.loaderStyles()} />
                    <p>Processing Payment...</p>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="heading-supplement mb3" dangerouslySetInnerHTML={{__html: this.props.break.description}}></div>
                    <div className="row details">
                        <div className="col-6 text-center">
                            <b>${Number.parseFloat(this.props.break.price).toFixed(2)}</b>
                            <span>Cost per spot</span>
                        </div>
                        <div className="col-6 text-center">
                            <b>{this.props.break.quantity - this.props.break.entries_sold}</b>
                            <span>Spots Remaining</span>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render = () => {
        // return (
        //     <div className={`break break-${this.props.index}`}>
        //         <div className="justify-content-center mb-4">
        //             <div className="dark-round break-sale">
        //                 <h2 className="mt-0">{this.props.break.name}</h2>
        //                 {this.renderLoader()}
        //                 <div className="text-center">
        //                     {this.props.userSignedIn && this.props.user && this.props.user.status === 'active' ? this.renderPaypalInputs() : this.renderGuestLoginLink()}
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // )

        return (
            <div className={`break break-${this.props.index}`}>
                <div className="mb-4">
                    <div className="dark-round break-sale">
                        <div className="row">
                            <div className="col-4">
                                <img src={`/personal_breaks/${this.props.break.id}/image`} className="break-image" />
                            </div>
                            <div className="col-8">
                                <h2 className="mt-2 pb-0 text-left plain">{this.props.break.name}</h2>
                                <div className="divider"></div>
                                {this.renderLoader()}
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        { this.props.userSignedIn && this.props.user && this.props.user.status === 'active' ? this.renderPaypalInputs() : this.renderGuestLoginLink() }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
