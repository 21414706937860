import React from 'react'

const YearModal = ({ modalId, draftPreference }) => {
    if (draftPreference.userDraftPreferencePresent) {
        return null;
    }

    return (
        <div className="modal dark fade" id={modalId}>
            <div className="modal-dialog" style={{ right: '260px' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title">{draftPreference.name}</h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>
                            You are currently using the UBB Default Draft Preferences.
                            Any spots you purchase from a {draftPreference.name} box will use
                            the UBB {draftPreference.year} default team draft preferences. To
                            activate your custom draft preferences, drag and drop
                            any team and then YOU are in control!
                        </p>
                        <p>
                            PLEASE NOTE: UBB's draft preferences are initially set
                            based on rookie class content but are not updated daily.
                            Please take the time to customize your team draft preferences.
                            Any changes made to your preferences must be done BEFORE a break has sold out.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">OK, I get it!</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default YearModal
