import React from 'react'
import Paypal from '../Paypal'
import BreakRedeem from './BreakRedeem'

export default class BreakPurchaseRow extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: 1
        }
    }

    updateQuantity = e => {
        /**
         * Don't allow the user to update the quantity input value to anything
         * higher than the spots remaining on the break.
         */
        if (e.target.value > this.props.spotsRemaining) {
            return false
        }

        this.setState({ quantity: e.target.value ? Math.floor(e.target.value) : null })
    }

    rewardPointsAvailable = () => {
        return this.props.break.reward_points_required_per_entry <= Number.parseFloat(this.props.rewardPoints);
    }

    render = () => {
        return (
            <div className="row justify-content-center mb-4" style={{ display: this.props.loading ? 'none' : 'flex' }}>
                <div className="col-sm-12 col-md-10 col-lg-6">
                    <div className="row">
                        <div className="col">
                            <label>Select Quantity:</label>

                            <input
                                onChange={this.updateQuantity}
                                style={{ width: '100%' }}
                                value={this.state.quantity}
                                type="number"
                                step="1"
                                min="1"
                                className="form-control mb-2 qty-field"
                                max={this.props.break.max_spots - this.props.break.entries_sold}
                                placeholder="Enter desired quantity..." />

                            <Paypal
                                setLoading={this.props.setLoading}
                                token={this.props.token}
                                getQuantity={() => this.state.quantity}
                                getBreak={() => this.props.break} />

                            {
                                this.props.break.reward_points_redemption_allowed && (
                                    <BreakRedeem
                                        rewardPoints={this.props.rewardPoints}
                                        cost={this.props.break.reward_points_required_per_entry}
                                        quantity={this.state.quantity}
                                        breakId={this.props.break.id}
                                        token={this.props.token}
                                        spotsAvailable={this.props.break.max_spots - this.props.break.entries_sold}
                                        isEnabled={this.rewardPointsAvailable()}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
