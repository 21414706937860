import React from 'react'
import axios from 'axios'
import PersonalBreak from './PersonalBreak'
import Cable from '../../lib/Cable'
import Break from '../breaks/Break'

export default class Index extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            breaks: []
        }
    }

    componentDidMount() {
        axios.get('/personal_breaks.json').then(response => {
            this.setState({ breaks: response.data });
        });

        Cable.connect('PersonalBreaksChannel', {
            received: data => {
                console.log('Updated personal break data: ', data);
                this.setState({ breaks: data.data });
            }
        });
    }

    render = () => {
        return (
            <div className="page" id="register">
                <div className="container-xl">
                    <div className="row justify-content-center mb-4">
                        <div className="col-8">
                            <h1 className="page-header">Buy Personal Break</h1>
                            <p className="intro">
                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                elit. In dui purus, commodo non ex et, auctor ultrices
                                felis. Sed at mauris sodales, sodales mauris ac,
                                aliquet nibh. Orci varius natoque penatibus et magnis.
                            </p>
                        </div>
                    </div>
                    {
                        this.state.breaks.map((bk, key) => {
                            return (
                                <Break
                                    key={key}
                                    break={bk}
                                    token={this.props.token}
                                    user={this.props.user}
                                    userSignedIn={this.props.user_signed_in} />
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}
