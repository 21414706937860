import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import SortableFaq from './SortableFaq'

const SortableFaqList = SortableContainer(({ faqs }) => {
    return (
        <div>
            {
                faqs.map((faq, index) => (
                    <SortableFaq key={`item-${faq.id}`} index={index} faq={faq} />
                ))
            }
        </div>
    )
})

export default SortableFaqList