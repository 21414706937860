import React from 'react'
import DraftOrderBreak from './DraftOrderBreak'
import sortObjects from 'sort-objects-array'

const DraftOrderBreaksContainer = ({ breaks, activeBreak, handleBreakClick }) => {
    return sortObjects(breaks, 'sold_out_at').map(b => {
        return (
            <DraftOrderBreak
                key={b.id}
                bk={b}
                handleClick={handleBreakClick}
                selected={b.id === activeBreak} />
        )
    })
}

export default DraftOrderBreaksContainer
