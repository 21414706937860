import React from 'react';
import axios from 'axios';
import Inputs from './Inputs';

export default class Form extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sports: [],
            selectedSport: this.props.drp.sport_id,
            values: this.props.drp.values || []
        };
    }

    componentDidMount() {
        axios.get('/sports.json').then((response) => {
            this.setState({ sports: response.data });
        });
    }

    selectSport = (e) => {
        this.setState({ selectedSport: e.target.value });

        axios.get(`/admin/sports/${e.target.value}/teams.json`).then((response) => {
            this.setState({
                values: response.data.map((team, index) => {
                    return {
                        spot: index + 1,
                        percentage: null
                    };
                })
            });
        });
    }

    percentageChange = (values) => {
        this.setState({ values: values });
    }

    render() {
        return (
            <div>
                <form action={this.props.form_path} method='POST'>

                    <input type="hidden" name="authenticity_token" value={this.props.token} />

                    <input type="hidden" name="default_reward_point[values]" value={JSON.stringify(this.state.values)} />

                    <div className="row">
                        <div className="col-6">
                            <label>Sport&nbsp;*</label>
                            {
                                this.props.sport_name ? <p>{this.props.sport_name}</p> : (
                                    <select
                                        required
                                        className="form-control"
                                        name="default_reward_point[sport_id]"
                                        onChange={this.selectSport}
                                        value={this.state.selectedSport || ''}>

                                        <option value=""></option>
                                        {
                                            this.state.sports.map((sport, key) => {
                                                return (
                                                    <option key={key} value={sport.id}>{sport.name}</option>
                                                )
                                            })
                                        }

                                    </select>
                                )
                            }
                        </div>

                        <div className="col-6">

                            <Inputs
                                onChange={this.percentageChange}
                                values={this.state.values} />

                        </div>

                    </div>
                    <br />
                    <button role="submit" className='btn btn-success mb-5'>{`${this.props.drp.id ? 'Update' : 'Create'} default draft preference`}</button>
                </form>
            </div>
        );
    }
}
