import React from 'react'

const CountingDownBreak = ({ name, seconds }) => {
    return (
        <div className="draft-col cards card-col">
            <h2 className="mt-0">{name}</h2>
            <div className="spots-remaining text-center digital red-glow">
                Drafting in
            </div>
            <div className="glow-text glow-lg text-center">
                {seconds}
            </div>
        </div>
    )
}

export default CountingDownBreak
