import React from 'react';

export default class Inputs extends React.Component {

    returnNewValues = (e, spot) => {
        return this.props.values.map((v) => {
            if (spot === v.spot) {
                return {
                    ...v,
                    percentage: e.target.value
                };
            } else {
                return v;
            }
        });
    }

    render() {
        if (!this.props.values.length) {
            return null;
        }

        return (
            <div>
                <table>
                    <thead>
                        <tr>
                            <td>Spot</td>
                            <td style={{ textAlign: 'center' }}>%</td>
                            {
                                this.props.showTotal && (
                                    <td style={{ textAlign: 'center' }}>#</td>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.values.map((value, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{value.spot}</td>
                                        <td>
                                            <input
                                                onChange={e => this.props.onChange(this.returnNewValues(e, value.spot))}
                                                className="form-control"
                                                type="number"
                                                min="0"
                                                step=".5"
                                                defaultValue={value.percentage || undefined} />
                                        </td>
                                        {
                                            this.props.showTotal && (
                                                <td>
                                                    <input
                                                        style={{ cursor: 'not-allowed' }}
                                                        className="form-control"
                                                        type="text"
                                                        disabled
                                                        value={((value.percentage / 100) * this.props.revenue).toFixed(2)} />
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}
