import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import SortableTeam from './SortableTeam'

const SortableTeamList = SortableContainer(({ teams }) => {
    return (
        <ul className='list-group'>
            {
                teams.map((team, index) => (
                    <SortableTeam
                        key={`item-${team.id}`}
                        index={index}
                        team={{...team, index: index}} />
                ))
            }
        </ul>
    )
})

export default SortableTeamList