import React, { useEffect, useState } from 'react';
import AuctionBidModal from './AuctionBidModal';
import AuctionBidHistory from './AuctionBidHistory';
import Cable from '../../lib/Cable';
import AuctionCountDown from './AuctionCountDown';
import Team from '../../lib/Team';
import axios from 'axios'

const MyAuctionItem = (props) => { 
	// const parentAuction = props.auctions.filter(auction => auction.id === props.auctionItem.break_id)[0];
	// const filteredBids = props.bids.filter(bid => bid.item_id === props.auctionItem.id);

	const [auctionItem, setAuctionItem] = useState(props.auctionItem);
	const [bids, setBids] = useState([]);
	const [showHistory, setShowHistory] = useState(false);
	const [auction, setAuction] = useState({});
	const [isExpired, setIsExpired] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		// get the latest bids on for each auction item
		axios.get(`/items/${props.auctionItem.id}/bids/bids_history.json`).then((response) => {
			// console.log("Updated item and bids data: ", response.data);
			setBids(response.data.bids)
			setAuction(response.data.auction)
		});

		// connect to the resource specific channel and update the item data if there's any changes from rails side
		Cable.connectResource('ItemChannel', auctionItem.id, {
			received: data => {
				// console.log("Updated item and bids data: ", data);
				setAuctionItem(data.updated_item);
				setBids(data.updated_bids);
			}
		});
		
		/**
		 * at the start of every page load we need to check once if the item
		 * is expired so that we can render the correct action buttons
		 */
      checkExpiration();
      // check if there's a reminder set on the item
		checkForReminder();
	}, [])

	const modalId = () => {
		// this sets the id so the bootstrap modal knows which one to open
		return `break-details-${auctionItem.id}`;
	};

	const toggleHistory = () => {
		setShowHistory(!showHistory)
   }
   
   // check if the user was ever a bidder on the item
   const checkIsBidder = () => {
		let isBidder = false;
		for (const bid of bids) {
			if (bid.user_id === props.user?.id) {
				isBidder = true;
				break;
			}
		}
		return isBidder;
	}

	const bidStatusBadge = () => {
		// find out if the item's expired
		const today = new Date();
      const itemEndDate = new Date(auctionItem.offset_date);
      
      // check if the user ever bid on this item or if they just set it as a reminder
      if (checkIsBidder()) {

         // check if the item's expired
         if (itemEndDate >= today) {

            // if the item hasn't expired then figure out if the user is the high bidder or is outbid
            if (auctionItem.user_id === props.user?.id) {
					const maxBid = parseFloat(bids.slice().sort((a, b) => parseFloat(b.max_bid) - parseFloat(a.max_bid)).find(bid => bid.user_id === props.user?.id).max_bid);

               return <div className="auction-item-details-status badge badge-success">HIGH BID: ${maxBid.toFixed(2)}</div>
            }
            else {
               return <div className="auction-item-details-status badge badge-danger">OUT BID</div>
            } // end of if statement
         }
         else {
            if (auctionItem.user_id === props.user?.id) {
               /**
                * if the item has expired and the user is the winning bidder
                * then check if the user has paid for the item yet or not
                */
               if (auctionItem.paid) {
                  return <div className="auction-item-details-status badge badge-success">PAID</div>
               }
               else {
                  return <div className="auction-item-details-status badge badge-success">WON</div>
               }
            }
            else {
               return <div className="auction-item-details-status badge badge-danger">LOST</div>
            } // end of if statement
         } // end of if statement
      }
      else {
         return <div className="auction-item-details-status badge"></div>
      }
	}

	const renderBidButton = () => {
		// figure out when the item expires 
		const today = new Date();
		const itemEndDate = new Date(auctionItem.offset_date);

		// if it hasn't expired yet render the update button
		if (!isExpired && itemEndDate >= today) {
			return (
				<button
					className="btn btn-primary"
					data-toggle='modal'
					data-target={`#${modalId()}`}
					title="Place a bid" >
					UPDATE BID
				</button>
			)
		}
		else {
			/**
			 * if the item has expired
			 * and the last bidding user is the user
			 * and the item hasn't been paid for
			 */
			if (isExpired && auctionItem.user_id === props.user.id && !auctionItem.paid) {
				return (
					<a href={props.paymentPath}
						className="btn btn-primary"
						title="Make a payment"
						data-turbolinks="false">
						PAY
					</a>
				)
			}
			else {
				// else the user either lost the auction or already paid for it
				return null;
			} // end of if statement
		} // end of if statement
	}

	const renderTimer = () => {
		// find out when the item expires
		const today = new Date();
		const itemEndDate = new Date(auctionItem.offset_date);

		if (itemEndDate >= today) {
			// if the item hasn't expired then render the countdown timer
			return (
				<td className="auction-item-meta">
					<span className="auction-item-meta-label">ENDS IN:</span>
					<AuctionCountDown auctionItem={auctionItem} hideActions={hideActions} />
				</td>
			);
		}
		else {
			if (auctionItem.user_id === props.user.id) {
				if (auctionItem.paid) {
					// if the item's expired, the user is the winner, and they've already paid for it then don't display anything
					return <td className="auction-item-meta"></td>;
				}
				else {
					/** 
					 * if the item's expired, the user is the winner, and they haven't paid for it yet
					 * then calculate the due date 30 days from the item's offset date
					 */
					let dueDate = new Date(auctionItem.auction_data.auction_payment_due_date);
					
					return (
						<td className="auction-item-meta">
							<span className="auction-item-meta-label">PAYMENT DUE:</span>
							<span> {dueDate.toLocaleDateString()}</span>
						</td>
					);
				} // end of if statement
			}
			else {
				// the user lost the bid so find the last bid they made on this item
				const lastBid = bids.find(bid => bid.user_id === props.user.id)
				const value = lastBid?.current_bid ? parseFloat(lastBid.current_bid).toFixed(2) : 0;
				return (
					<td className="auction-item-meta">
						<span className="auction-item-meta-label">YOUR BID:</span>
						<span> ${value}</span>
					</td>
				);
			} // end of if statement
		} // end of if statement
	}

	const hideActions = (data) => {
		setIsExpired(data);
	}
	
	const checkExpiration = () => {
		const today = new Date();
		const itemEndDate = new Date(auctionItem.offset_date);
		if (itemEndDate < today) {
			setIsExpired(true);
		}
	}

   // check if the user has marked this item for a reminder
	const checkForReminder = () => {
      const foundReminder = props.itemReminders.find(reminder => reminder.item_id === props.auctionItem.id && reminder.user_id === props.user?.id && reminder.reminder_on);
      if (foundReminder) {
         setIsChecked(true);
      }
   }

	const toggleReminder = (e) => {
		// make post request to set the reminder status on this item_reminder
		axios.post(`/items/${auctionItem.id}/item_reminders/set_reminder.json`, {
				authenticity_token: props.token,
				item_reminder: {
					item_id: auctionItem.id,
					user_id: props.user.id,
					reminder_on: e.target.checked
				}
		});
		setIsChecked(e.target.checked);
	}

	const formatAuctionName = (auctionName, itemName) => {
		return (
			<div>
				{itemName}
				<br />
				<span style={{ fontSize: '12px' }}>
					{auctionName}
				</span>
			</div>
		)
	}

	return (
		<>
			<tr className="auction-item">
				<td className="auction-item-logo">
					{
						props.auctionItem.image_data.team_data === "no_data" ? 
							<span></span>
							:
							<img src={Team.imagePath(props.auctionItem.image_data.team_data, props.auctionItem.image_data.sport_data)} alt="team" />
					}
				</td>
				<td className="auction-item-details">
					<div className="auction-item-details-name">{formatAuctionName(auctionItem.auction_break_name, auctionItem.title)}</div>
					{bidStatusBadge()}
				</td>
				<td className="auction-item-meta">
					<span className="auction-item-meta-label">
						{new Date(auctionItem.offset_date) >= new Date() ? "CURRENT BID" : "FINAL BID"}
					</span>
					<span className="auction-item-meta-detail">${auctionItem.current_bid ? parseFloat(auctionItem.current_bid).toFixed(2) : parseFloat(auctionItem.starting_bid_amount).toFixed(2)}</span>
				</td>
				{renderTimer()}
				<td className="auction-item-actions">
					<label className="show-history" title="Show bid history">
						<input
							type="checkbox"
							onChange={toggleHistory}
						/>
						<div className="btn btn-outline btn-icon">
							<i className="far fa-history"/>
						</div>
					</label>
					{
						!isExpired && 
							<label className="get-alerts" title="Add to my watchlist">
								<input type="checkbox"
                           checked={isChecked}
                           onChange={toggleReminder} />
								<div className="btn btn-outline btn-icon">
									<i className="far fa-eye-slash auction-alert-off"/>
									<i className="far fa-eye auction-alert-on"/>
								</div>
							</label>
					}
					{renderBidButton()}
				</td>
			</tr>
			{showHistory &&
				<AuctionBidHistory
				bids={bids}
				auctionItem={auctionItem} />
			}
			<AuctionBidModal
				auction={auction}
				auctionItem={auctionItem}
				modalId={modalId()}
				token={props.token}
				user={props.user}
				userSignedIn={props.userSignedIn}
				bids={bids} />
		</>
	)
}

export default MyAuctionItem