import React from 'react';
import PaymentSpinner from '../spinners/PaymentSpinner';
import BreakDetailsModal from './BreakDetailsModal';
import axios from 'axios';

export default class Break extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            excludedTeams: []
        };
    }

    componentDidMount() {
        /**
         * Whenever the break has excluded teams we fetch the individual teams from the server in order to display
         * those teams to the user in the break details page. Any break-related data from the server can be loaded
         * here as well, although we could run this same routine here in the modal component itself...
         */
        if (this.props.break.excluded_teams && this.props.break.excluded_teams.length) {
            axios.get(`/breaks/${this.props.break.id}/excluded_teams.json`).then(({ data }) => {
                this.setState({ excludedTeams: data });
            });
        }
    }

    setLoading = loading => {
        this.setState({ loading: loading });
    }

    spotsRemaining = () => {
        return this.props.break.max_spots - this.props.break.entries_sold;
    }

    renderLoader = () => {
        if (this.state.loading) {
            return <PaymentSpinner />;
        } else {
            return (
                <div className="break-controls">
                    <div className="row details">
                        <div className="col-6">
                            <b>${Number.parseFloat(this.props.break.cost_per_spot).toFixed(2)}</b>
                            <span>Cost per {this.props.break.unit_of_sale && this.props.break.unit_of_sale !== 'other' ? this.props.break.unit_of_sale : 'spot'}</span>
                        </div>

                        <div className="col-6">
                            <b>{this.spotsRemaining()}</b>
                            <span>{`${ this.props.personal ? '#' : 'Spots' } Remaining`}</span>
                        </div>
                    </div>
                    <div className="view">
                        <button
                            className="btn btn-primary"
                            title='View and purchase this break'
                            data-toggle='modal'
                            data-target={`#${this.modalId()}`}>
                            { this.props.personal ? 'Purchase' : 'View Break Info/Purchase' }
                        </button>
                    </div>
                </div>
            )
        }
    }

    modalId = () => {
        return `break-details-${this.props.break.id}`;
    }

    render() {
        return (
            <div className={`break break-${this.props.index}`}>
                <div className="break-image-container">
                    <img src={`/api/breaks/${this.props.break.id}/image`} className="break-image" />
                </div>
                <div className="break-below-image">
                    <div className="break-heading">
                        <h2 className="plain">{this.props.break.name}</h2>
                    </div>
                    {this.renderLoader()}

                    <BreakDetailsModal
                        modalId={this.modalId()}
                        break={this.props.break}
                        setLoading={this.setLoading}
                        token={this.props.token}
                        loading={this.state.loading}
                        spotsRemaining={this.spotsRemaining()}
                        guest={!this.props.userSignedIn}
                        activeUser={this.props.user && this.props.user.status === 'active'}
                        rewardPoints={this.props.rewardPoints}
                        profileComplete={this.props.profileComplete}
                        excludedTeams={this.state.excludedTeams}
                        breakDetailsModalId={this.props.breakDetailsModalId} />
                </div>
            </div>


        );
    }
}
