import React from 'react';
import axios from 'axios';

export default class PersonalBreakPaypal extends React.Component {

    componentDidMount() {
        /**
         * The break ID
         */
        const breakId = this.props.break.id;
        /**
         * Rails authenticity token
         */
        const token = this.props.token;
        /**
         * Price of hte item
         */
        const price = Number.parseFloat(this.props.break.price).toFixed(2);
        /**
         * The callback in order to get the quantity.
         *
         * This is passed as a callback to this component because all of the Paypal logic
         * here is ran at componentDidMount. Therefore, when the parent component updates
         * its quantity, this method will not update. Therefore, if we use initial props,
         * the quantity will never be updated dynamically. The easiest way to do that is just
         * to pass a callback from the parent. You could also make a method here to dynamically
         * get its version of props, but the parent callback can be passed in place.
         */
        const getQuantity = this.props.getQuantity;

        const getName = this.props.break.name;

        const setLoading = this.props.setLoading;

        paypal.Buttons({
            createOrder: function(data, actions) {
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            value: price * getQuantity()
                        }
                    }]
                });
            },
            onApprove: function(data, actions) {
                console.log('Approved: ', { data: data, actions: actions });

                setLoading(true);

                axios.get(`/personal_breaks/${breakId}/check_spots/${getQuantity()}.json`).then((response) => {
                    if (response.data.available) {
                        return actions.order.capture().then(function(details) {
                            console.log('Capture details: ', details);

                            axios.post(`/personal_breaks/${breakId}/personal_break_orders.json`, {
                                authenticity_token: token,
                                personal_break_order: {
                                    price_total: price * getQuantity(),
                                    price_per_item: price,
                                    quantity: getQuantity(),
                                    description: getName(),
                                    paypal_transaction: details.id
                                }
                            }).then((response) => {
                                if (response.data.success) {
                                    window.location.assign(`/personal_breaks/${response.data.order.personal_break_id}/personal_break_orders/${response.data.order.id}`);
                                } else {
                                    setLoading(false);
                                    alert(response.data.message);
                                }
                            });
                        });
                    } else {
                        setLoading(false);
                        alert('ERROR: All spots have been sold.');
                    }
                });
            }
        }).render(`#paypal-button-container-${breakId}`);
    }

    render() {
        return (
            <div className='mt-4' id={`paypal-button-container-${this.props.break.id}`}></div>
        );
    }
}
