import React from 'react'
import moment from 'moment'
import axios from 'axios'
import Cable from '../../lib/Cable'

export default class Countdown extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            /**
             * Days remaining
             */
            days: null,
            /**
             * Hours remaining
             */
            hours: null,
            /**
             * Minutes remaining
             */
            minutes: null,
            /**
             * Seconds remaining
             */
            seconds: null,
            /**
             * Next break date
             */
            nextBreakDate: null,
            /**
             * Hide countdown
             */
            hideCountdown: null,
            /**
             * Headline
             */
            headline: null,
            /**
             * Have we loaded the intitial announcement data?
             */
            announcementLoaded: false
        }
    }

    componentDidMount() {
        this.fetchActive()

        Cable.connect('AnnouncementsChannel', {
            received: ({ data }) => {
                console.log('Announcements data received: ', data);
                this.updateAnnouncement(data);
            }
        });
    }

    fetchActive = () => {
        axios.get('/announcements/active.json').then(({ data }) => this.updateAnnouncement(data, true));
    }

    updateAnnouncement = (data, isInitialLoad) => {
        this.setState({
            nextBreakDate:      data.next_break_date,
            hideCountdown:      data.hide_countdown,
            headline:           data.headline,
            announcementLoaded: true
        }, () => {
            if (!isInitialLoad) {
                return;
            }

            this.calculateTimeRemaining();

            if (window.calculateInterval) {
                window.clearInterval(window.calculateInterval)
            }

            window.calculateInterval = window.setInterval(this.calculateTimeRemaining, 1000)
        });
    }

    /**
     * Calculate how much time is between the next break date and right now, setting
     * the individual days, minutes, and seconds into state.
     */
    calculateTimeRemaining = () => {
        const now      = moment()
        const next     = moment(this.state.nextBreakDate)
        const diff     = next.diff(now)
        const duration = moment.duration(diff)

        if (duration.days() === 0 && duration.hours() === 0 && duration.minutes() === 0 && duration.seconds() === 0) {
            // stop the calculation so it stops at 0
            window.clearInterval(window.calculateInterval)
            // wait a second (so it has passed the 0 difference above) and refresh the page
            window.setTimeout(() => window.location.reload(), 1000)
        }

        this.setState({
            days:    duration.days(),
            hours:   duration.hours(),
            minutes: duration.minutes(),
            seconds: duration.seconds()
        });
    }

    handleHeadlineClick = () => {
        if (this.state.hideCountdown) {
            window.location.assign('/breaks/live');
        }
    }

    render = () => {
        if (!this.state.announcementLoaded) {
            return null;
        }

        return (
            <div className="countdown-banner">
                <div onClick={this.handleHeadlineClick} className={`unit headline ${this.state.hideCountdown ? 'link' : ''}`}>{this.state.headline}</div>
                {
                    this.state.hideCountdown ? null : (
                        <div className="time">
                            <div className="unit days">{this.state.days}<span className="sub">D</span></div>
                            <div className="unit hours">{this.state.hours}<span className="sub">H</span></div>
                            <div className="unit minutes">{this.state.minutes}<span className="sub">M</span></div>
                            <div className="unit seconds">{this.state.seconds}<span className="sub">S</span></div>
                        </div>
                    )
                }
            </div>
        )
    }
}
